<template>
  <div v-if="show" class="popup">
    <div class="popup-content" :class="styleClass">
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  // props:['message'],
  data() {
    return {
      show: false,
      message: "",
      styleClass: "",
    };
  },
  methods: {
    showMessage(message, styleClass = "") {
      this.message = message;
      this.styleClass = styleClass;
      this.show = true;
      setTimeout(() => {
        this.closeMessage();
      }, 3000); // 3秒后自动关闭
    },
    closeMessage() {
      this.show = false;
    },
  },
};
</script>

<style scoped lang="scss">
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.3); */
  z-index: 1000;
}

.popup-content {
  padding: 15px;
	background-color: #000000;
	opacity: 0.5;
  color: #fff;
}
</style>

