import { checkLesson } from '@/api/student/live.js';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { Message, MessageBox } from 'element-ui';

export const getDeviceType = () => {
  var isAndroid = /(android)/i.test(navigator.userAgent);
  var isIOS = /(iphone|ipad|ipod|ios)/i.test(navigator.userAgent);

  if (isAndroid) {
    return "android";
  } else if (isIOS) {
    return "ios";
  } else {
    return "unknown";
  }
}
//学生查询该课程是否可上
export const checkLessonFn = (lessonId, teachingType) => {
  let data = {
    lessonId: lessonId,
    teachingType: teachingType
  }
  let ok = {}
  return checkLesson(data).then((ok) => {
    return ok = ok.data
  })

}
//网页协议替换（http替换成https）
export const locationFn = () => {
  if (location.protocol === "http:" && !window.location.hostname.includes('192.168.0') && window.location.hostname !== "localhost") {
    location.href = location.href.replace(/^http:/, "https:");
  }
}

//教师端时区处理
//这里是对返回的北京时间处理成使用者本机（所在时区）的时间处理
export const timezone = (backtime) => {
  //1.subtract(8, 'hour')因为返回的是北京时间，减八小时获得世界标准时
  //2.utc(backtime)获取本机与标准时的偏差
  //3.local()转换成本地时间
  //4.format('YYYY-MM-DD HH:mm')时间格式化
  let nowtime = dayjs(backtime).subtract(8, 'hour').utc(backtime).local().format('YYYY-MM-DD HH:mm');
  return nowtime;
}
//学生端不需要进行时区转换（后端处理），这里只做时间的格式化
export const stutimezone = (backtime) => {
  let nowtime = dayjs(backtime).format('MM月DD日 HH:mm');
  return nowtime;
}
//  控制文字省略 显示几行 vantui TextEllipsis 组件主要代码
//  expand = '',collapse = '' 添加完之后  就会刚好空出这几个字  自行添加 展开和收起按钮
export let countNumTextFn = (dom, row, posi = 'end', expand = '', collapse = '') => {
  let rows = row, // 最大显示行数，在截断文本前显示的行数（默认为1）
    dots = '...', // 省略号的字符（默认为...
    content = dom.innerText, //需要截断的文本内容
    expandText = expand, // 当省略号折叠时显示的文本（默认为空）
    collapseText = collapse, // 当省略号展开时显示的文本（默认为空）
    position = posi; // 省略号在文本中的位置。可以是 'start'、'end' 或 'middle'（默认为 'end'）

  let text = ''; // 文本内容的响应式引用
  const expanded = false; // 是否展开的响应式引用
  let hasAction = false; // 是否显示展开/折叠操作的响应式引用
  const root = dom; // 根元素的引用

  const actionText = expanded ? collapseText : expandText

  const pxToNum = (value) => {
    if (!value) return 0;
    const match = value.match(/^\d*(\.\d*)?/);
    return match ? Number(match[0]) : 0;
  };
  const calcEllipsised = () => {
    const cloneContainer = () => {
      if (!root)
        return;
      const originStyle = window.getComputedStyle(root);
      const container2 = document.createElement("div");
      const styleNames = Array.prototype.slice.apply(originStyle);
      styleNames.forEach((name2) => {
        container2.style.setProperty(name2, originStyle.getPropertyValue(name2));
      });
      container2.style.position = "fixed";
      container2.style.zIndex = "-9999";
      container2.style.top = "-9999px";
      container2.style.height = "auto";
      container2.style.minHeight = "auto";
      container2.style.maxHeight = "auto";
      container2.innerText = content;
      document.body.appendChild(container2);
      return container2;
    };
    const calcEllipsisText = (container2, maxHeight2) => {

      const end = content.length;
      const calcEllipse = () => {
        const tail = (left, right) => {
          if (right - left <= 1) {
            if (position === "end") {
              // console.log(content.slice(0, left) + dots);
              return content.slice(0, left) + dots;
            }
            return dots + content.slice(right, end);
          }
          const middle2 = Math.round((left + right) / 2);
          if (position === "end") {
            container2.innerText = content.slice(0, middle2) + dots + actionText;
          } else {
            container2.innerText = dots + content.slice(middle2, end) + actionText;
          }
          if (container2.offsetHeight > maxHeight2) {
            if (position === "end") {
              return tail(left, middle2);
            }
            return tail(middle2, right);
          }
          if (position === "end") {
            return tail(middle2, right);
          }
          return tail(left, middle2);
        };
        container2.innerText = tail(0, end);
      };
      const middleTail = (leftPart, rightPart) => {
        if (leftPart[1] - leftPart[0] <= 1 && rightPart[1] - rightPart[0] <= 1) {
          return content.slice(0, leftPart[0]) + dots + content.slice(rightPart[1], end);
        }
        const leftMiddle = Math.floor((leftPart[0] + leftPart[1]) / 2);
        const rightMiddle = Math.ceil((rightPart[0] + rightPart[1]) / 2);
        container2.innerText = content.slice(0, leftMiddle) + dots + content.slice(rightMiddle, end) + expandText;
        if (container2.offsetHeight >= maxHeight2) {
          return middleTail([leftPart[0], leftMiddle], [rightMiddle, rightPart[1]]);
        }
        return middleTail([leftMiddle, leftPart[1]], [rightPart[0], rightMiddle]);
      };
      const middle = 0 + end >> 1;
      position === "middle" ? container2.innerText = middleTail([0, middle], [middle, end]) : calcEllipse();
      return container2.innerText;
    };
    const container = cloneContainer();
    if (!container)
      return;
    const {
      paddingBottom,
      paddingTop,
      lineHeight
    } = container.style;
    const maxHeight = Math.ceil((Number(rows) + 0.5) * pxToNum(lineHeight) + pxToNum(paddingTop) + pxToNum(paddingBottom));
    if (maxHeight < container.offsetHeight) {
      hasAction = true;
      text = calcEllipsisText(container, maxHeight);
    } else {
      hasAction = false;
      text = content;
    }
    document.body.removeChild(container);

  };
  calcEllipsised()

  return {
    text,
    content,
    hasAction
  }
}
// 获取当前浏览器的语言
export const getLanguageFn = () => {
  return navigator.language || navigator.browserLanguage;
}

// 获取当地的时区
export const getTimeZoneFn = () => {
  return new Date().getTimezoneOffset() / 60 * -1
}

export const createDeviceIdFn = () => {
  return new Promise((resolve, reject) => {
    FingerprintJS.load().then(fpPromise => {
      fpPromise.get().then(result => {
        const visitorId = result.visitorId;
        resolve(visitorId);
      }).catch(error => {
        reject(error);
      })
    }).catch(error => {
      reject(error);
    })
  });
}

//复制文本并保持样式(只传入需要复制的文本）
export const copyText = (textToCopy) => {
  try {
    let tempTextArea = document.createElement('textarea');
    tempTextArea.style.position = 'fixed';
    tempTextArea.style.top = '99999px';
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    Message.success('复制成功');
  } catch (e) {
    Message.error('复制失败');
  }
}

function showErrorAlert(message) {
  MessageBox.alert(message, '上传失败', {
    confirmButtonText: '确定',
    type: 'error',
  });
}
export const getBase64 = () => {
  return new Promise((resolve, reject) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (event) => {
      event.preventDefault(); // 阻止默认事件触发
      const file = event.target.files[0];
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      if (!isJPG && !isPNG) {
        showErrorAlert('只支持上传 JPG/PNG 格式的图片');
        reject();
      } else {
        const reader = new FileReader();
        reader.onload = (event) => {
          // const fileData = event.target.result;
          // const fileData = event.target.result;
          resolve(file);
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  });
}
export const getBase64Fn = () => {
  return new Promise((resolve, reject) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.multiple = '9';

    input.onchange = async (event) => {
      event.preventDefault(); // 阻止默认事件触发
      const files = event.target.files;
      if (files.length > 9) {
        reject('最多上传9个文件');
        return;
      }
      const allowedFileTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
      ];
      const promises = [];

      for (const file of files) {

        const isType = allowedFileTypes.includes(file.type);
        if (!isType) {
          showErrorAlert('上传图片格式错误');
          reject('上传图片格式错误');
          return;
        } else {
          promises.push(file);
        }
        Promise.all(promises)
          .then(() => resolve(promises))
          .catch((error) => reject(error));
      }
    };

    input.click();
  });
};

// const readFileAsBase64 = (file) => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => {
//       resolve(reader.result);
//     };
//     reader.onerror = (error) => {
//       reject(error);
//     };
//   });
// };

// 文件上传
export const getFolderFn = () => {
  return new Promise((resolve, reject) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = true; // 允许多选
    input.onchange = (event) => {
      event.preventDefault(); // 阻止默认事件触发
      const files = event.target.files;

      if (files.length > 9) {
        reject('最多上传9个文件');
        return;
      }

      const allowedFileTypes = [
        "application/pdf",
        "application/msword",
        "application/zip",
        "application/x-zip-compressed",
        "video/mp4",
        "video/avi",
        "video/mov",
        "video/mpg",
        "application/vnd.ms-excel",
        ".pptx",
        ".ppt",
        ".word",
        ".doc",
        "audio/mpeg",
        "application/vnd.ms-powerpoint", // ppt
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xlsx
        "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
      ];
      const promises = [];
      for (const file of files) {
        console.log(file.type, '文件类型');
        const isType = allowedFileTypes.includes(file.type);
        if (!isType) {
          showErrorAlert('上传文件格式错误');
          reject('上传文件格式错误');
          return;
        } else {
          promises.push(file);
        }
      }

      Promise.all(promises)
        .then(() => resolve(promises))
        .catch((error) => reject(error));
    };
    input.click();
  });
};



// 获取文件后缀 www.xxx.doc 返回doc
export const getUrlExt = (url) => {
  const index = url.lastIndexOf('.');
  let ext
  if (url.indexOf('?') > -1) {
    ext = url.slice(index + 1, url.indexOf('?'))
  } else {
    ext = url.slice(index + 1, url.length)
  }
  return ext.toLowerCase(); // 转换为小写，确保准确性
}
export const downLoad = (urls, fileName, fileType) => {
  let currentProtocol = window.location.protocol; //获取当前的协议
  if (currentProtocol === "https:") {
    urls = urls.replace("http:", "https:");
  } else if (currentProtocol != "http:" || fileType == '' || fileType == undefined || fileType == null) {
    alert("该资料下载地址无效");
    return;
  }
  let xhr = new XMLHttpRequest();
  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4) {
      if (xhr.status == 200) {
        let blob = new Blob([xhr.response]); // 创建二进制对象
        let newfileName = `${fileName}${fileType}`; // 下载的文件名
        let link = document.createElement("a"); // 创建a标签
        link.href = URL.createObjectURL(blob); // 设置a标签的下载链接
        link.download = newfileName; // 设置a标签的下载文件名
        link.click();
        // 在触发点击事件之后再删除节点
        link.addEventListener("click", () => {
          document.body.removeChild(link);
        });
      } else {
        alert("下载文件失败");
      }
    }
  };
  xhr.open("GET", urls, true); // 发送GET请求
  xhr.responseType = "blob"; // 将返回结果设置为Blob对象(不设置的话无法解析内容)
  xhr.send(); // 发送请求
}

export const extractFileNameAndExtension = (url)=> {
  // 使用正则表达式匹配文件名及扩展名部分
  const regex = /\/([^/]+)(\.[^.]*)$/;
  const match = url.match(regex);

  if (match && match.length >= 3) {
      const fileName = match[1]; // 文件名
      const fileExtension = match[2]; // 扩展名

      return {
          fileName: decodeURIComponent(fileName),
          fileExtension: decodeURIComponent(fileExtension)
      };
  } else {
      throw new Error("Invalid URL or no valid file name and extension found.");
  }
}

export const  bytesToMb = (byteCount)=> {
  // 1 MB = 1024 * 1024 Bytes
  var mbCount = byteCount / (1024 * 1024);
  // 使用 toFixed 方法保留两位小数
  return parseFloat(mbCount.toFixed(2));
}