 let { Howl } = require('./howler.js')
 
 export const audioFn = ({url,loop,callback}) =>{
    return new Howl({
        src: [url],
        loop: loop,
        preload: true,
		onend: function() {
		   if(typeof callback =='function'){
			   callback()
		   }
		}
      })
}