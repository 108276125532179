import Layout from '@/views/teacher/layout/index.vue'
/**
 * hidden: true                   为true 就不会在左边的侧边栏显示
 * alwaysShow: true               为true 就算只有一个子路由 也会渲染成下拉形式
 * redirect: noRedirect           重定向路由
 * name:'router-name'             路由名字 必须设置！！！（后期修改 和 path 名一样 但是首字母要大写 避免路由重名警告 两个名字一样是用来做选中项判断的）
 * meta : {
    roles: ['admin','editor']    角色
    title: 'title'               左侧栏名字和面包屑导航需要
    icon: 'svg-name'/'el-icon-x' 左侧栏图标
    img: 'class-center'          图片的名字    图片要放在 assets/common/sidebar 下
    activeImg:'all-class-active',左边目录 激活后的图表名字
    noCache: true                如果为true 页面就会被<keep-alive>缓存起来
    affix: true                  如果为true 这个tag就会被固定住 不会被删除
    breadcrumb: false            如果设置为false，该项将隐藏在breadcrumb中（默认值为true）
    activeMenu: '/example/list'  如果设置了路径，侧边栏将突出显示您设置的路径 动态路径 固定左侧导航激活项
    childrenHidden : true        为true 就不会被左边的目录渲染
    showSildeBar ： true          为true 就不会被隐藏 在左侧导航中 前提是 不在顶部导航栏 左侧却需要显示导航栏
  }
 */
export const asyncTeacher = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path(.*)',
    component: () => import('@/views/teacher/redirect/index.vue')
  }]
},
{
  path: '/teacher',
  redirect: '/class-center',
  hidden: true,
  meta: {
    noCache: true
  },
},
{
  path: '/class-center',
  component: Layout,
  name: 'Class-center',
  redirect: '/class-center/index',
  meta: {
    title: '上课中心',
    img: 'class-center'
  },
  // alwaysShow: true,
  children: [{
    path: 'index',
    name: 'class-center',
    component: () => import('@/views/teacher/class-center/index.vue'),
    meta: {
      title: '总课表',
      img: 'all-class',
      activeImg: 'all-class-active',
    }
  },
  {
    path: 'system-class',
    name: 'System-class',
    component: () => import('@/views/teacher/class-center/system-class.vue'),
    meta: {
      title: '体系课',
      img: 'system-class',
      activeImg: 'system-class-active',
    }
  },
  {
    path: 'open-class',
    name: 'Open-class',
    component: () => import('@/views/teacher/class-center/open-class.vue'),
    meta: {
      title: '公开课',
      img: 'open-class',
      activeImg: 'open-class-active',
    }
  },
  {
    path: 'class-directory',
    name: 'Class-directory',
    component: () => import('@/views/teacher/class-center/class-directory.vue'),
    meta: {
      title: '班级目录',
      childrenHidden: true,
    }
  },
  {
    path: 'class-detail',
    name: 'Class-detail',
    component: () => import('@/views/teacher/class-center/class-detail.vue'),
    meta: {
      title: '课程详情',
      childrenHidden: true,
    }
  },
  ]
},
{
  path: '/operation-center',
  component: Layout,
  redirect: '/operation-center/index',
  hidden: false,
  name: 'Operation-center',
  meta: {
    title: '作业系统',
    img: 'operation-center',
  },
  alwaysShow: true,
  children: [{
    path: 'index',
    name: 'operation-center',
    component: () => import('@/views/teacher/operation-center/index.vue'),
    meta: {
      title: '作业管理',
      img: 'all-class',
      activeImg: 'all-class-active',
    },
  },
  {
    path: 'edit-homework',
    name: 'Edit-homework',
    hidden: true,
    component: () => import('@/views/teacher/operation-center/base/edit-homework.vue'),
    meta: {
      childrenHidden: true,
      title: '作业编辑',
      img: 'all-class',
      activeImg: 'all-class-active',
    },
  },
  {
    path: 'correct',
    name: 'correct-center',
    component: () => import('@/views/teacher/operation-center/correct.vue'),
    meta: {
      title: '作业批改',
      img: 'all-class',
      activeImg: 'all-class-active',
    },
  },
  {
    path: 'template',
    name: 'operation-center-template',
    component: () => import('@/views/teacher/operation-center/template.vue'),
    meta: {
      title: '模板中心',
      keepAlive: true, // 配置需要缓存
      img: 'all-class',
      activeImg: 'all-class-active',
      childrenHidden: true,
    },
  },
  {
    path: 'question',
    name: 'operation-center-question',
    component: () => import('@/views/teacher/operation-center/question.vue'),
    meta: {
      title: '题库中心',
      keepAlive: true, // 配置需要缓存
      img: 'all-class',
      activeImg: 'all-class-active',
      childrenHidden: true,
    },
  },
  {
    path: 'correct-index',
    name: 'Correct-index',
    hidden: true,
    component: () => import('@/views/teacher/operation-center/correct/correct-index.vue'),
    meta: {
      title: '批改作业',
      childrenHidden: true,
      img: 'all-class',
      activeImg: 'all-class-active',
    }
  },
  {
    path: 'index-index',
    name: 'Index-index',
    hidden: true,
    component: () => import('@/views/teacher/operation-center/index/index-index.vue'),
    meta: {
      childrenHidden: true,
      title: '编辑作业',
      img: 'all-class',
      activeImg: 'all-class-active',

    }
  },
  {
    path: 'question-index',
    name: 'Question-index',
    hidden: true,
    component: () => import('@/views/teacher/operation-center/question/question-index.vue'),
    meta: {
      title: '编辑题库',
      childrenHidden: true,
      img: 'all-class',
      activeImg: 'all-class-active',
    }
  },
  {
    path: 'template-index',
    hidden: true, // 暂时隐藏
    name: 'Template-index',
    component: () => import('@/views/teacher/operation-center/template/template-index.vue'),
    meta: {
      title: '编辑模板',
      img: 'all-class',
      activeImg: 'all-class-active',
      childrenHidden: true,
    },
  },
  ]
},
{
  path: '/resource-center',
  component: Layout,
  redirect: '/resource-center/index',
  name: 'Resource-center',
  meta: {
    title: '资源中心',
    img: 'course-materials'
  },
  // alwaysShow: true,
  children: [
    {
      path: 'index',
      name: 'resource-center',
      component: () => import('@/views/teacher/resource-center/index.vue'),

      meta: {
        title: '资源广场',
        img: 'resource',
        activeImg: 'resource1',
        keepAlive:true
      },
    },
    {
      path: 'search-resource',
      name: 'search-resource',
      component: () => import('@/views/teacher/resource-center/SearchResource.vue'),
      meta: {
        title: '资源搜索',
        img: 'resource',
        activeImg: 'resource1',
        childrenHidden: true
      },
    },
    // {
    //   path: 'my-resource',
    //   name: 'my-resource',
    //   component: () => import('@/views/teacher/resource-center/MyResource.vue'),

    //   meta: {
    //     title: '我的资源',
    //     img: 'my-resource',
    //     activeImg: 'my-resource1',
    //   },
    // },
    {
      path: 'my-favorite',
      name: 'my-favorite',
      component: () => import('@/views/teacher/resource-center/MyFavorite.vue'),

      meta: {
        title: '我的收藏',
        img: 'collect',
        activeImg: 'collect1'
      },
    },
    {
      path: 'book-detail',
      name: 'book-detail',
      component: () => import('@/views/teacher/resource-center/BookDetail.vue'),
      meta: {
        childrenHidden: true,
        title: '书本详情',
        img: 'book-detail'
      },
    },
    {
      path: 'view-file',
      name: 'view-file',
      component: () => import('@/views/teacher/resource-center/ViewFile.vue'),
      meta: {
        childrenHidden: true,
        title: '查看文件',
        img: 'view-file'
      },
    },
  ]
},
{
  path: '/course-history',
  component: Layout,
  name: 'Course-history',
  redirect: '/course-history/index',
  meta: {
    title: '课程回放',
    img: 'course-history'
  },
  // alwaysShow: true,
  children: [{
    path: 'index',
    name: 'course-history',
    component: () => import('@/views/teacher/course-history/index.vue'),
    meta: {
      title: '课程回放',
      img: 'course-history'
    },
  }]
},
{
  path: '/class-history',
  component: Layout,
  redirect: '/class-history/index',
  name: 'Class-history',
  hidden:true,
  meta: {
    title: '上课历史',
    img: 'class-history'
  },
  // alwaysShow: true,
  children: [{
    path: 'index',
    name: 'class-history',
    component: () => import('@/views/teacher/class-history/index.vue'),
    meta: {
      title: '上课历史',
      img: 'class-history'
    },
  }]
},
{
  path: '/set-center',
  component: Layout,
  name: 'Set-center',

  // component: () => import('@/views/teacher/set-center/index.vue'),
  redirect: '/set-center/information',
  hidden: true, // 顶部导航栏不显示 
  meta: {
    title: '账号设置',
    img: 'class-history',
    showSildeBar:true // 左侧导航栏需要显示
  },
  children: [{
    path: 'information',
    name: 'Information',
    component: () => import('@/views/teacher/set-center/information'),
    meta: {
      title: '账号信息',
      img: 'all-class',
      activeImg: 'all-class-active',
    },
  },
  {
    path: 'privacy',
    name: 'Privacy',
    component: () => import('@/views/teacher/set-center/privacy'),
    meta: {
      title: '账号绑定',
      img: 'system-class',
      activeImg: 'system-class-active',
    },
  }
  ]
},
{
  path: '/notice-center',
  component: Layout,
  name: 'Notice-center',
  redirect: '/notice-center/index',
  hidden: true,
  meta: {
    title: '通知中心',
    img: 'notice-center'
  },
  // alwaysShow: true,
  children: [{
    path: 'index',
    name: 'notice-center',
    component: () => import('@/views/teacher/notice-center/index.vue'),
    meta: {
      title: '通知中心',
      img: 'class-history'
    },
  }]
},
{
  path: '/live',
  name: 'Live',
  component: () => import('@/views/teacher/live/index.vue'),
  hidden: true,
  meta: {
    title: '直播'
  }
},
]
