import request from '@/utils/request.js'


// 获取该教师的日历信息
export const getcalender = (searchStartDate, searchEndDate, timezone) => {
  return request({
    url: '/api-tch/web/lessonClass/getScheduleByMonth',
    method: 'post',
    data: {
      searchStartDate,
      searchEndDate,
      timezone
    },
    headers: {
      'content-type': 'application/json',
    }

  })
}
//根据课程表日期查询课程详情
export const getdateclass = (month, timezone) => {
  return request({
    url: '/api-tch/web/lessonClass/getCourseDetail',
    method: 'get',
    params: {
      month,
      timezone
    }
  })
}
// 上课
// export const attend = (lessonId) => {
//   return request({
//     url: '/api-tch/web/lessonClass/attend',
//     method: 'post',
//     params: {
//       lessonId
//     }
//   })
// }
// 统计老师授课、班级、学生数量
export const getteacher = () => {
  return request({
    url: '/api-tch/lessonClass/static',
    method: 'post',
  })
}
// 获取全部班级信息
export const classlist = (statusType, timezone, courseId, current) => {
  return request({
    // url: '/api-tch/lessonClass/getListClass',
    url: '/api-tch/lessonClass/clzPage',
    method: 'post',
    params: {
      statusType,
      timezone,
      courseId,
      current,
    }
  })
}
// 查看公开课课堂详情
export const pubLessonDetail = (lessonId) => {
  return request({
    url: '/api-tch/lessonCourse/pubLessonDetail',
    method: 'post',
    params: {
      lessonId
    }
  })
}
//获取课程时长配置列表
export const coureDurations = () => {
  return request({
    url: '/api-user/common/coureDurations',
    method: 'post',
  })
}
// 查询当前教师可用科目列表
export const subjectList = () => {
  return request({
    url: '/api-tch/lessonCourse/subjectList',
    method: 'post',
  })
}
//修改公开课
export const updatePubCourse = (ruleForm) => {
  return request({
    url: '/api-tch/lessonCourse/updatePubCourse',
    method: 'post',
    data: ruleForm,
    headers: {
      'content-type': 'multipart/form-data',
    }
  })
}
// 创建公开课
export const addPubCourse = (ruleForm) => {
  return request({
    url: '/api-tch/lessonCourse/addPubCourse',
    method: 'post',
    data: ruleForm,
    headers: {
      'content-type': 'multipart/form-data',
    }
  })
}
// 公开课调课申请
export const pubAdjust = (lessonId, classDate, StartClassTime, timezone) => {
  return request({
    url: '/api-tch/lessonClass/pubAdjust',
    method: 'post',
    params: {
      lessonId, classDate, StartClassTime, timezone
    }
  })
}
// 查询公开课课程详情
export const pubCourseDetail = (courseId) => {
  return request({
    url: '/api-tch/lessonCourse/pubCourseDetail',
    method: 'post',
    params: {
      courseId
    }
  })
}
// 分页查询公开课列表
export const pubCourseList = (courseName, clzStatus, current, timeStatus, orderStatus) => {
  return request({
    url: '/api-tch/lessonCourse/pubCourseList',
    method: 'post',
    params: {
      courseName,
      clzStatus,
      current,
      timeStatus,
      orderStatus,
    }
  })
}
//公开课查询条件
export const pubCourseStatus = () => {
  return request({
    url: '/api-tch/lessonCourse/pubCourseStatus',
    method: 'post',
  })
}
//班级列表-条件查询
export const classquery = () => {
  return request({
    url: '/api-tch/lessonClass/getCourseList',
    method: 'post',
  })
}
// 获取点击班级的章节信息
export const classlisttext = (courseId, timezone, classId) => {
  return request({
    url: '/api-tch/web/lessonClass/getListChapter',
    method: 'get',
    params: {
      courseId,
      timezone,
      classId,
    }
  })
}
// 获取章节的简介信息
export const listclasstext = (lessonId) => {
  return request({
    url: '/api-tch/lessonCourse/lessonDetail',
    method: 'post',
    params: {
      lessonId
    }
  })
}
// 课堂表现列表
export const feedbackAllList = (lessonId) => {
  return request({
    url: '/api-tch/lessonClass/classPerformance',
    method: 'post',
    params: {
      lessonId
    }
  })
}
// 最后一堂课
export const isEndCourseApi = (lessonId) => {
  return request({
    url: '/api-tch/lessonClass/isEndCourse',
    method: 'post',
    params: {
      lessonId
    }
  })
}
// 教材详情
export const getTextBook = (classId) => {
  return request({
    url: '/api-tch/web/lessonClass/getTextBook',
    method: 'get',
    params: {
      classId
    }
  })
}
// 获取班级通讯录信息
export const maillist = (classId) => {
  return request({
    url: '/api-tch/lessonClass/selectStuList',
    method: 'post',
    params: {
      classId
    }
  })
}
// 获取班级通讯录点击学生的详细信息
export const mailliststudent = (stuId) => {
  return request({
    url: '/api-tch/lessonClass/getStuDetail',
    method: 'post',
    params: {
      stuId
    }
  })
}
// 当日日历课程表
export const dateclass = (lessonDate) => {
  return request({
    url: '/api-tch/lessonClass/teacherLessonStatusListByDate',
    method: 'post',
    params: {
      lessonDate,
    }
  })
}
//调课申请
export const course = (lessonId, classDate, StartClassTime, timezone) => {
  return request({
    url: '/api-tch/lessonClass/adjust',
    method: 'post',
    params: {
      lessonId,
      classDate,
      StartClassTime,
      timezone
    }
  })
}
//调课次数
export const coursecount = () => {
  return request({
    url: '/api-tch/lessonClass/staticLesson',
    method: 'post',
  })
}
// 上传课件
export const uploadLessonResource = (params) => {
  return request({
    url: '/api-tch/lessonClass/uploadLessonResource',
    method: 'post',
    params: params,
  })
}
// 上传base64字符串的图片
export const uploadImage = (base64Str) => {
  return request({
    url: '/api-tch/fileOperate/uploadByBase64Str',
    method: 'post',
    data: { base64Str }, // 将base64字符串放入请求体中
    headers: {
      'content-type': 'multipart/form-data',
    },
  })
}
// 获取题库模板
export const TemplateList = (lessonId) => {
  return request({
    url: '/api-tch/question/getQuestionTemplateList',
    method: 'post',
    params: {
      lessonId,
    }
  })
}
// 布置作业(通过题库)
export const ByTemplate = (data) => {
  return request({
    url: '/api-tch/question/createQuestionByTemplate',
    method: 'post',
    params: data,
  })
}
// 课件列表
export const courseware = (lessonId, teachingType) => {
  return request({
    url: '/api-tch/lessonClass/getLessonCourseList',
    method: 'post',
    params: {
      lessonId, teachingType
    }
  })
}
// 课件批量删除
export const deleteCourse = (lessonResourceIds, teachingType) => {
  return request({
    url: '/api-tch/lessonClass/deleteLessonCourseWeb',
    method: 'post',
    data: { lessonResourceIds, teachingType },
    headers: {
      'content-type': 'application/json',
    },
  })
}
//课堂作业列表
export const classroomlist = (lessonId) => {
  return request({
    url: '/api-tch/question/lessonQuestionList',
    method: 'post',
    params: {
      lessonId,
    }
  })
}
//删除作业
export const deleteClassroomList = (questionPaperIdList) => {
  return request({
    url: '/api-tch/question/deleteQuestionPaper',
    method: 'post',
    data: questionPaperIdList,
    headers: {
      'content-type': 'multipart/form-data',
    }
  })
}
//管理作业中心的删除作业
export const deleteClassroomLists = (questionPaperIdList) => {
  return request({
    url: '/api-tch/web/questionPaper/delete',
    method: 'post',
    data: questionPaperIdList,
    headers: {
      'content-type': 'multipart/form-data',
      // 'content-type': 'multipart/json',//原为json格式
    }
  })
}
//课堂学生试卷详情
export const getTestPaper = (data) => {
  return request({
    url: '/api-tch/question/studentQuestionDetail',
    method: 'post',
    params: data,
  })
}
// 获取题库模板
export const questionbank = (lessonId) => {
  return request({
    url: '/api-tch/question/getQuestionTemplateList',
    method: 'post',
    params: {
      lessonId
    },
  })
}
//作业批改状态
export const homeworkstatus = () => {
  return request({
    url: '/api-tch/question/correctPaperStateList',
    method: 'post',
  })
}
// 作业提醒(单个)
export const homeworkRemind = (data) => {
  return request({
    url: '/api-tch/web/questionPaper/reminderHomework',
    method: 'post',
    params: {
      data
    }
  })
}
// 作业提醒(一键提醒--整个班级)
export const homeworkReminds = (data) => {
  return request({
    url: '/api-tch/web/question/batchReminderHomework',
    method: 'post',
    data: data,
    // headers: {
    //   'content-type': 'multipart/json',
    // }
  })
}


//布置日常作业
export const createHomeWork = (data) => {
  return request({
    url: '/api-tch/question/createHomeWork',
    method: 'post',
    data: data,
    headers: {
      'content-type': 'application/json',
    }
  })
}
//课堂作业列表
// export const classwork = (lessonId) => {
//   return request({
//     url: '/api-tch/question/lessonQuestionList',
//     method: 'post',
//     params: {lessonId},

//   })
// }
//课堂作业详情
export const correct = (lessonId) => {
  return request({
    url: '/api-tch/question/lessonQuestionList',
    method: 'post',
    params: {
      lessonId,
    }
  })
}
//公告图片上传
export const noticeImgs = (files, fromType) => {
  return request({
    url: '/api-user/file/uploadImgs',
    method: 'post',
    data: {
      files,
      fromType, //1:试题 2:公告图片
    }
  })
}
// 添加公告
export const addNotice = (formdata) => {
  return request({
    url: '/api-tch/notice/addNotice',
    method: 'post',
    data: formdata,
    headers: {
      'content-type': 'multipart/form-data',
    }
  })
}
// 公告列表
export const announcement = (data) => {
  return request({
    url: '/api-tch/notice/getListNotice',
    method: 'post',
    params: data
  })
}
// 删除公告
export const deleteNotice = (noticeId) => {
  return request({
    url: `/api-tch/notice/deleteNotice?noticeId=${noticeId}`,
    method: 'POST',

  })
}
// 修改公告
export const updateNotice = (formdata) => {
  return request({
    url: '/api-tch/notice/updateNotice',
    method: 'POST',
    data: formdata,
    headers: {
      'content-type': 'multipart/form-data',
    }
  })
}
// -----------------------------------------设置中心----------------------------
//教师信息查询
export const teacherInformation = (teaId) => {
  return request({
    url: '/api-tch/user/tchInfo',
    method: 'post',
    params: {
      teaId,
    }
  })
}
// 更新用户信息
export const updateUser = (data) => {
  return request({
    url: '/api-tch/user/updateUser',
    method: 'post',
    params: data,
  })
}
// 查询账户隐私
export const teacherPrivacy = () => {
  return request({
    url: '/api-tch/user/accountInfo',
    method: 'post',
  })
}
//设置密码
export const Password = (data) => {
  return request({
    url: '/api-tch/user/setPwd',
    method: 'post',
    params: data
  })
}
// 修改密码
export const setPassword = (data) => {
  return request({
    url: '/api-tch/user/updatePwd',
    method: 'post',
    params: data
  })
}
// 微信绑定
export const setWachat = (data) => {
  return request({
    url: '/api-tch/user/webSocialBind/webWx',
    method: 'post',
    params: data,
  })
}

// 社交账户解绑
export const unWebWx = (data) => {
  return request({
    url: '/api-tch/user/socialUnBind/webWx',
    method: 'post',
    params: data,
  })
}
// QQ账户绑定（没页面且不主要，目前没做，后续加上）
export const setQQ = () => {
  return request({
    url: '/api-user/user/socialBind/qq',
    method: 'post',
  })
}
// 分页查询教师系统消息
export const teacherNotice = (current, size) => {
  return request({
    url: '/api-tch/im/sysMsg',
    method: 'post',
    params: {
      current,
      size
    }
  })
}
// 消息未读数
export const getUnReadNum = () => {
  return request({
    url: '/api-tch/im/getUnReadNum',
    method: 'post',
  })
}
// 反馈详情
export const teacherNoticeText = (id) => {
  return request({
    url: '/api-user/feedback/detail',
    method: 'post',
    params: {
      id,
    }
  })
}
// 手机号绑定
export const bindingPhone = (data) => {
  return request({
    url: '/api-tch/user/phoneBind',
    method: 'post',
    data: data,
    headers: {
      'content-type': 'multipart/form-data',
    }
  })
}

// 手机号换绑
export const updatePhoneBind = (data) => {
  return request({
    url: '/api-tch/user/updateMobile',
    method: 'post',
    data: data,
    headers: {
      'content-type': 'multipart/form-data',
    }
  })
}
// 获取短信校验码
export const getPhoneBind = (data) => {
  return request({
    url: '/api-user/user/code/sms',
    method: 'post',
    params: data,
  })
}






// ------------------------------------------作业系统--------------------------------
// 作业列表
export const homeworkList = (data) => {
  return request({
    url: '/api-tch/web/questionPaper/list',
    method: 'get',
    params: data

  })
}
// 题库列表
export const questionList = (data) => {
  return request({
    url: '/api-tch/web/question/list',
    method: 'get',
    params: data
  })
}

//模板详情
export const templateDetail = (data) => {
  return request({
    url: '/api-tch/web/questionPaper/templateDetail',
    method: 'get',
    params: data
  })
}
// 删除题库
export const questionDelete = (data) => {
  return request({
    url: '/api-tch/web/question/delete',
    method: 'post',
    data: data,
    headers: {
      'content-type': 'multipart/form-data',
      // 'content-type': 'multipart/json',//原为json格式
    }
  })
}
// 布置作业
export const publishHomework = (data) => {
  return request({
    url: '/api-tch/web/questionPaper/add',
    method: 'get',
    params: data,
    headers: {
      'content-type': 'application/json',
    }

  })
}
// // 作业管理
// export const homeworkManage = (data) => {
//   return request({
//     url: '/api-tch/web/questionPaper',
//     method: 'post',
//     params:
//       {data}

//   })
// }

//查询课程详情

export function courseDetail(params) {
  return request({
    url: '/api-tch/web/course/detail',
    method: 'get',
    params: params
  })
}


export function listApi(params) {
  return request({
    url: '/api-tch/web/question/list',
    method: 'get',
    params: params
  })
}

export function deleteApi(data) {
  return request({
    url: '/api-tch/web/question/delete',
    method: 'post',
    data: data
  })
}

export function fetchSubjectSelectListApi(pid) {
  return request({
    url: '/api-tch/web/subject/queryListByPid?pid=' + pid,
    method: 'get'
  })
}

export function fetchTextBookSelectListApi(query) {
  return request({
    url: '/api-tch/web/textbook/selectList',
    method: 'get',
    params: query
  })
}

export function getCatchModel() {
  return request({
    url: '/api-tch/web/educationDict/getCatchModel',
    method: 'get'
  })
}

export function getGradeListApi() {
  return request({
    url: '/api-tch/web/grade/gradeList',
    method: 'get'
  })
}
//查询筛选条件
export function selectListApi(cataId) {
  return request({
    url: '/api-tch/web/educationDict/querySelectList?cataId=' + cataId,
    method: 'get'
  })
}
// ------------------------------------------------
//获取当前教师参与的所有班级，以及班级下对应的作业列表
export function correction(data) {
  return request({
    url: '/api-tch/question/classQuestionList',
    method: 'post',
    params: data,
  })
}

//作业批改状态
export function correctStatus(cataId) {
  return request({
    url: '/api-tch/question/correctPaperStateList',
    method: 'post'
  })
}
//批改作业列表
export function correctList(data) {
  return request({
    url: '/api-tch/web/answerPaper/list',
    method: 'get',
    params: data
  })
}
//科目信息
export function fetchStageSubjectSelectListApi(pid) {
  return request({
    url: '/api-tch/web/subject/queryListByPid',
    method: 'get',
    params: {
      pid
    }
  })
}
// export function detailApi(params) {
//   return request({
//     url: '/admin-hwjy-api/user/userDetail',
//     method: 'get',
//     params: params
//   })
// }
// 批改作业详情
export function questionDetail(data) {
  return request({
    url: '/api-tch/web/answerPaper/detail',
    method: 'get',
    params: data

  })
}
// 提交批改的作业
export function submitCorrectPaper(paper, list, commentList, imageList) {
  return request({
    url: '/api-tch/web/answerPaper/submitCorrectPaper',
    method: 'post',
    data: {
      paper,
      list,
      commentList,
      imageList
    },
    headers: {
      'content-type': 'application/json',
    }
  })
}
//删除评论
export function deleteComment(data) {
  return request({
    url: '/api-tch/comment/delete',
    method: 'post',
    params: data
  })
}
// 新增评论
export function addComment(data) {
  return request({
    url: '/api-user/comment/add',
    method: 'post',
    params: data
  })
}
// ------------------------------------------------------
// 题库中心

export function questionCenter(data) {
  return request({
    url: '/api-tch/web/questionPaper/list',
    method: 'get',
    params: data
  })
}
//请求机构信息
export function fetchMerchantSelectListApi(query) {
  return request({
    url: '/api-tch/web/authorize/merchant/selectList',
    method: 'get',
    params: query
  })
}

// ---------------------------------------------------------上课历史--------------------------
// 上课历史
export function tchLessonList(data) {
  return request({
    url: '/api-tch/web/log/tchLessonList',
    method: 'post',
    data: data,
    headers: {
      'content-type': 'multipart/form-data',
    }
  })
}
// ------------------------------------------------------课程资料-----------------------------------
// 课件列表（pc端）
export function lessonCourseList(data) {
  return request({
    url: '/api-tch/web/lessonClass/lessonCourseList',
    method: 'get',
    params: data,
  })
}
// 课件批量删除（web）
export function deleteLessonCourseWeb(data) {
  return request({
    url: '/api-tch/lessonClass/deleteLessonCourseWeb',
    method: 'post',
    data: data,
  })
}
// -------------------------------------------------------回放管理
//回放管理列表
export function lessonRivewList(data) {
  return request({
    url: '/api-tch/web/review/lessonReviewList',
    method: 'post',
    params: data,
  })
}
// 视频客户端上传签名
export function getSignatures(data) {
  return request({
    url: '/api-tch/qqvod/common/getSignature',
    method: 'get',
    params: data,
  })
}
// 回放管理--视频上传
export function addQqVodApi(data, callback) {
  return request({
    url: '/api-tch/web/review/common/add',
    method: 'post',
    params: data,
    onUploadProgress: progressEvent => {
      // 原生获取上传进度的事件
      if (progressEvent.lengthComputable) {
        callback && callback(progressEvent);
      }
    },
    headers: {
      'content-type': 'application/json',
    },
  })
}
// 教师端-常见问题
export function askedQuestion() {
  return request({
    url: '/api-tch/web/question/askedQuestion',
    method: 'get',
  })
}
// 根据科目编号获取教材列表
export function textBooks(data) {
  return request({
    url: '/api-tch/lessonCourse/textBooks',
    method: 'post',
    params: data,
  })
}
// 获取课程类别
export function courseTypes() {
  return request({
    url: '/api-tch/lessonCourse/courseTypes',
    method: 'post',
  })
}
// 单个图片上传
export function uploadImgCover(data) {
  return request({
    url: '/api-tch/fileOperate/uploadImg',
    method: 'post',
    data: data,
    headers: {
      'content-type': "multipart/form-data"
    },
  })
}
//截图上传
export const apiUploadByBase64Str = (data, callback) => {
  return request({
    url: '/api-tch/fileOperate/uploadByBase64Str',
    method: 'post',
    data,
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress: progressEvent => {
      // 原生获取上传进度的事件
      if (progressEvent.lengthComputable) {
        callback && callback(progressEvent);
      }
    }
  })
}
// 上传文件附件
export const uploadFile = (params, callback, cancelToken) => {
  // const source = axios.CancelToken.source();
  // // // 添加source.token到请求参数中
  // params.cancelToken = source.token;
  return request({
    url: '/api-tch/fileOperate/uploadFile',
    method: 'post',
    data: params,
    headers: {
      'content-type': 'multipart/form-data',
    },
    cancelToken: cancelToken,
    // cancelToken: source,
    onUploadProgress: progressEvent => {
      // 原生获取上传进度的事件
      if (progressEvent.lengthComputable) {
        callback && callback(progressEvent);
      }
    }
  });
  // 添加cancel方法用于取消请求
  // requestInstance.cancel = () => {
  //   source.cancel('取消了');
  // };
  // return requestInstance;
}

//视频转码（视频上传）
export function addQqVod(data) {
  return request({
    url: '/api-tch/qqvod/common/add',
    method: 'post',
    data: data,
  })
}
//批量云端课堂资料删除
export function deleteLessonCourse(data) {
  return request({
    url: '/api-tch/fileOperate/delObjects',
    method: 'post',
    data: data,
    headers: {
      'content-type': 'application/json',
    },
  })
}
//批改日常作业
export function submitCorrectHomeWork(paper, answerSubmitDto, commentList) {
  return request({
    url: '/api-tch/question/submitCorrectHomeWork',
    method: 'post',
    data: { paper, answerSubmitDto, commentList },
    headers: {
      'content-type': 'application/json',
    },
  })
}
// 修改开始批改时间 
export function updateCorrectTime(data) {
  return request({
    url: '/api-tch/web/questionPaper/updateCorrectTime',
    method: 'get',
    params: data,
  })
}

// 班级的作业统计列表 /api-tch/question/classQuestionCountList
export function classQuestionCountList(data) {
  return request({
    url: '/api-tch/question/classQuestionCountList',
    method: 'post',
    data: data,
    headers: {
      'content-type': "multipart/form-data"
    }
  })
}
// 作业批改状态 /api-tch/question/correctPaperStateList
export function correctPaperStateList(data) {
  return request({
    url: '/api-tch/question/correctPaperStateList',
    method: 'post',
    data: data,
    headers: {
      'content-type': "multipart/form-data"
    }
  })
}
// 课堂学生试卷详情 /api-tch/question/studentQuestionDetail
export function studentQuestionDetail(data) {
  return request({
    url: '/api-tch/question/studentQuestionDetail',
    method: 'post',
    data: data,
    headers: {
      'content-type': "multipart/form-data"
    }
  })
}

//  /api-tch/question/batchReminderHomework
export function batchReminderHomework(data) {
  return request({
    url: '/api-tch/question/batchReminderHomework',
    method: 'post',
    data: data,
  })
}

// 班级详情 /api-tch/web/lessonClass/classDetail
export function classDetail(data) {
  return request({
    url: '/api-tch/web/lessonClass/classDetail',
    method: 'post',
    data: data,
  })
}

// /api-tch/lessonClass/PerformanceDetail
export function PerformanceDetail(data) {
  return request({
    url: '/api-tch/lessonClass/PerformanceDetail',
    method: 'post',
    data: data,
  })
}

// 课堂的作业统计列表 /api-tch/question/lessonQuestionCountList
export function lessonQuestionCountList(data) {
  return request({
    url: '/api-tch/question/lessonQuestionCountList',
    method: 'post',
    data: data,
  })
}

// 修改课堂章节名 /api-tch/lessonCourse/updateChapterName
export function updateChapterName(data) {
  return request({
    url: '/api-tch/lessonCourse/updateChapterName',
    method: 'post',
    data: data,
  })
}

// 主页课程 /api-tch/lessonClass/teacherLessonStatusList
export function teacherLessonStatusList(data) {
  return request({
    url: '/api-tch/lessonClass/teacherLessonStatusList',
    method: 'post',
    data: data,
  })
}