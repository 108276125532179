<template>
  <div id="#app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
}
</script>
<style lang="scss">
// #html,body{
//   font-family: '黑体';
// }
</style>
