// dayjs 已经用cdn引入了 以后的dayjs方法 就可以用
//window.dayjs_plugin_扩展插件名 这种方式引入新的扩展插件
// dayjs调用的话 不需要是方法了
dayjs.locale('zh-cn')
dayjs.extend(window.dayjs_plugin_duration)
dayjs.extend(window.dayjs_plugin_utc);
dayjs.extend(window.dayjs_plugin_timezone);
dayjs.extend(window.dayjs_plugin_isBetween);
dayjs.extend(window.dayjs_plugin_relativeTime);
// 单位数阿拉伯数字转语文数字
 export let numberChange = (num) => {
    if (num < 0) return 0
    if (isNaN(num)) return 0
    return ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十'][num]
}

// 将秒数转化为时间
export let timeSeconds = (seconds) => {
    let secondsNum = Math.floor(seconds);
    const duration = dayjs.duration(secondsNum, 'seconds');
    const time = duration.format('mm:ss');
    return time
}