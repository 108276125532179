<template>
  <!-- vertical:false 横向滚动  @wheel.native.prevent 鼠标滚动-->
  <el-scrollbar ref="scrollContainer" :vertical="false" class="scroll-container" @wheel.native.prevent="handleScroll">
    <slot />
  </el-scrollbar>
</template>

<script>
const tagAndTagSpacing = 4 // tagAndTagSpacing 每个tag之间的空隙

export default {
  name: 'ScrollPane',
  data() {
    return {
      left: 0
    }
  },
  computed: {
    scrollWrapper() {
      return this.$refs.scrollContainer.$refs.wrap
    }
  },
  mounted() {
    // 添加滚动的监听 向外抛出滚动的监听事件
    this.scrollWrapper.addEventListener('scroll', this.emitScroll, true)
  },
  beforeDestroy() {
    // 销毁滚动的监听
    this.scrollWrapper.removeEventListener('scroll', this.emitScroll)
  },
  methods: {
    handleScroll(e) {
       // wheelDelta：获取滚轮滚动方向，向上120，向下-120，但为常量，与滚轮速率无关
      // deltaY：垂直滚动幅度，正值向下滚动。电脑鼠标滚轮垂直行数默认值是3
      // wheelDelta只有部分浏览器支持，deltaY几乎所有浏览器都支持
      // -e.deltaY * 40 这个 *40 不知道是啥意思
      const eventDelta = e.wheelDelta || -e.deltaY * 40
      const $scrollWrapper = this.scrollWrapper
       // 0到scrollLeft为滚动区域隐藏部分
      //  eventDelta / 4  这个/4 也不知道是什么意思
      $scrollWrapper.scrollLeft = $scrollWrapper.scrollLeft + eventDelta / 4
    },
    emitScroll() {
      this.$emit('scroll')
    },
    // 移动到目标tag
    moveToTarget(currentTag) {
      // 获取滚动的元素
      const $container = this.$refs.scrollContainer.$el
      // 获取元素的宽度 包括padding border 的值
      const $containerWidth = $container.offsetWidth
      // 获取 wapper 组件
      const $scrollWrapper = this.scrollWrapper
      // 获取tag 组件
      const tagList = this.$parent.$refs.tag
      // 第一个tag 和最后一个tag
      let firstTag = null
      let lastTag = null

      // 找到第一个tag 和最后一个tag 
      if (tagList.length > 0) {
        firstTag = tagList[0]
        lastTag = tagList[tagList.length - 1]
      }
      // 当前点击的tag 是否是第一个tag 
      if (firstTag === currentTag) {
        // 如果是第一个tag 就滚动到0
        $scrollWrapper.scrollLeft = 0
        // 如果是最后一个tag
      } else if (lastTag === currentTag) {
        // 他的结构是tags-view-container  > el-scrollbar__wrap
        // 里面的wrap的宽度超出container 用里面的宽度 减去 外面的宽度 
        // 就是要向左滑动的宽度
        // 滑动的距离是 el-scrollbar__wrap的总宽度 减去tags-view-container 元素的宽度
        $scrollWrapper.scrollLeft = $scrollWrapper.scrollWidth - $containerWidth
      } else {
        // 找到上一个tag和下一个tag
        // 找到当前tag的索引值
        const currentIndex = tagList.findIndex(item => item === currentTag)
        const prevTag = tagList[currentIndex - 1]
        const nextTag = tagList[currentIndex + 1]

        // 下一个tag距离左边的距离 = 下一个tag距离左边的距离 + 下一个tag自身的距离 + tag之间空隙的距离
        const afterNextTagOffsetLeft = nextTag.$el.offsetLeft + nextTag.$el.offsetWidth + tagAndTagSpacing

        // 上一个tag距离左边的距离 = 上一个tag距离左边的距离 - tag之间空隙的距离
        const beforePrevTagOffsetLeft = prevTag.$el.offsetLeft - tagAndTagSpacing

         // 如果下一个tag距离左边的距离 大于 需要往左滑动的距离 + tags-view-containe 元素的宽度
        //  是否点击的是右边边缘的tag 会滑动到下一个tag的距离
        // 说明这个tag的下一个tag 已经超出了tags-view-containe 可见范围
        //  所以会滑动到下一个tag的左边的距离
        // 简单点说就是最右边的下一个tag是否在可见范围内 是否被遮挡
        if (afterNextTagOffsetLeft > $scrollWrapper.scrollLeft + $containerWidth) {
          // 往左滑动的距离 = 下一个tag距离左边的距离 - tags-view-containe 元素的宽度
          $scrollWrapper.scrollLeft = afterNextTagOffsetLeft - $containerWidth

        // 是否点击的是左边边缘的tag  会滑动到上一个tag的距离
        // 上一个tag距离左边的距离是否 小于 往右滑动的距离 
        // 就会滑动到上一个tag距离左边的的距离
        } else if (beforePrevTagOffsetLeft < $scrollWrapper.scrollLeft) {
          // 往左滑的距离 = 上一个tag距离左边的距离
          $scrollWrapper.scrollLeft = beforePrevTagOffsetLeft
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.scroll-container {
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  width: 100%;
    :deep(.el-scrollbar__bar) {
      bottom: 0px;
    }
    :deep(.el-scrollbar__wrap) {
      height: 49px;
    }
}
</style>
