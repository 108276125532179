import request from '@/utils/request.js'
//查询当前用户下学员列表
export const stuList = () => {
  return request({
    url: '/api-user/user/stuList',
    method: 'post',
  })
}
// 查询当前学员上课中课堂
export const studying = () => {
  return request({
    url: '/api-user/web/study/studying',
    method: 'post',
  })
}
// 变更学员
export const assignStudent = (stuId) => {
  return request({
    url: '/api-user/user/assignStudent',
    method: 'post',
    params: {
      stuId
    },
  })
}
// 分页查询当前学员上课中之外课堂
export const lessons = (data) => {
  return request({
    url: '/api-user/web/study/lessons',
    method: 'post',
    data: data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 分页查询待上课课堂
export const ytsLessons = (current) => {
  return request({
    url: '/api-user/web/study/ytsLessons',
    method: 'post',
    params: {
      current
    }
  })
}
// 分页查询当前学员课堂回顾
export const reviewLessons = (current) => {
  return request({
    url: '/api-user/web/study/reviewLessons',
    method: 'post',
    params: {
      current
    }
  })
}
// 查看指定日期课程课堂信息
export const lessonThatDay = (theDay) => {
  return request({
    url: '/api-user/study/lessonThatDay',
    method: 'post',
    params: {
      theDay
    }
  })
}
// 获取学员指定年份课堂信息
export const lessonDate = (year) => {
  return request({
    url: '/api-user/study/lessonDate',
    method: 'post',
    params: {
      year
    }
  })
}
// 查询学员已购买课程列表
export const boughtCourses = (data) => {
  return request({
    url: '/api-user/study/boughtCourses',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 查看已购课程课堂详情
export const lessonDetail = (data) => {
  return request({
    url: '/api-user/course/lessonDetail',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}

//查看课程详情页
export const courseDetail = (data) => {
  return request({
    url: '/api-user/course/courseDetailPaid',
    method: 'post',
    data,
  })
}
// 置顶公告
export const topNotice = (data) => {
  return request({
    url: '/api-user/course/topNotice',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 公告列表
export const getListNotice = (data) => {
  return request({
    url: '/api-user/course/getListNotice',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 查询指定课堂教师信息列表
export const lessonTchs = (data) => {
  return request({
    url: '/api-user/course/classTchs',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 查看教师信息 
export const tchInfo = (data) => {
  return request({
    url: '/api-user/user/tchInfo',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 课堂作业列表
export const lessonQuestionPaperList = (data) => {
  return request({
    url: '/api-user/question/lessonQuestionPaperList',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 查看课堂表现 
export const lessonPerformance = (data) => {
  return request({
    url: '/api-user/study/lessonPerformance',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 学生查看课件列表 
export const chapterData = (data) => {
  return request({
    url: '/api-user/course/chapterData',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 分页查询系统消息 
export const sysMsg = (data) => {
  return request({
    url: '/api-user/im/sysMsg',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 查询系统消息详情 
export const sysMsgDetail = (data) => {
  return request({
    url: '/api-user/feedback/detail',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 更新用户信息 
export const updateUser = (data) => {
  return request({
    url: '/api-user/user/updateUser',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 查询账户隐私 
export const accountInfo = (data) => {
  return request({
    url: '/api-user/user/accountInfo',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 获取随机验证码 
export const randomToken = (data) => {
  return request({
    url: '/api-user/user/randomToken',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 获取短信校验码 (旧版)
export const sms = (data) => {
  return request({
    url: '/api-user/user/code/sms',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 获取短信验证码（已登录用户） 
export const create = (data) => {
  return request({
    url: '/api-user/sms/create',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 修改密码 
export const updatePwd = (data) => {
  return request({
    url: '/api-user/user/updatePwd',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}

// export const webWx = (data) => {
//   return request({
//     url: '/api-user/user/auth/webWx',
//     method: 'post',
//     data,
//     headers: {
//       'content-type': "multipart/form-data",
//     }
//   })
// }
// 更换手机号 
export const updateMobile = (data) => {
  return request({
    url: '/api-user/user/updateMobile',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 重置密码 
export const resetPwd = (data) => {
  return request({
    url: '/api-user/user/resetPwd',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 设置密码 
export const setPwd = (data) => {
  return request({
    url: '/api-user/user/setPwd',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
//微信绑定
export const webWx = (data) => {
  return request({
    url: '/api-user/user/webSocialBind/webWx',
    method: 'post',
    data: data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
//微信解绑
export const unWebWx = (data) => {
  return request({
    url: '/api-user/user/socialUnBind/webWx',
    method: 'post',
    params: data,
  })
}
// 作业答卷状态 
export const answerPaperStateList = () => {
  return request({
    url: '/api-user/question/answerPaperStateList',
    method: 'post'
  })
}
// 查看最近自学课章节详情 
export const recentlySelfStudy = (data) => {
  return request({
    url: '/api-user/study/recentlySelfStudy',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 查看自学课章节详情 
export const chapterDetail = (data) => {
  return request({
    url: '/api-user/course/chapterDetail',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 获取自学课视频播放地址 
export const chapterVideoUrl = (data) => {
  return request({
    url: '/api-user/course/chapterVideoUrl',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 更新自学课学习进度 
export const updateSSProgess = (data) => {
  return request({
    url: '/api-user/study/updateSSProgess',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 课程作业列表 
export const courseList = (data) => {
  return request({
    url: '/api-user/question/courseList',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 授课类型集合 
export const teachingType = (data) => {
  return request({
    url: '/api-user/study/teachingType',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 查询学员指定授课类型已购买课程列表 
export const purchasedCourses = (data) => {
  return request({
    url: '/api-user/study/purchasedCourses',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 完成查看视频回放
export const hasVisitReview = (data) => {
  return request({
    url: '/api-user/study/hasVisitReview',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 完成课堂表现查看 
export const hasVisitPerformance = (data) => {
  return request({
    url: '/api-user/study/hasVisitPerformance',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 公开课报名 
export const registerPubCourse = (data) => {
  return request({
    url: '/api-user/study/registerPubCourse',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 查看公开课课程详情 
export const detail = (data) => {
  return request({
    url: '/api-user/course/courseDetailPaid',
    method: 'post',
    data,
  })
}
// 学生端-常见问题 
export const askedQuestion = () => {
  return request({
    url: '/api-user/web/askedQuestion/askedQuestion',
    method: 'get',
  })
}