<template>
  <div class="navbar">
    <!-- 面包屑导航 -->
    <!-- <breadcrumb id="breadcrumb-container" class="breadcrumb-container" /> -->

    <div class="nav-top-center">
      <img src="@/assets/student/nav_imgs/LOGO.png" class="left-logo" alt="" />
      <div class="student-info">
        <div class="stu-info-img">
          <img :src="curStu.headImg" alt="" />
        </div>
        <p class="stu-info-name">{{ curStu.stuName }}</p>
        <el-dropdown
          class="avatar-container right-menu-item hover-effect"
          trigger="click"
        >
          <!-- 右边用户名和用户头像 -->
          <div class="avatar-wrapper">
            <div
              class="stu-info-change"
              v-if="stuLists.length > 1"
              @click="stuSwitch"
            >
              切换
            </div>
            <div class="stu-info-change" v-else-if="stuLists.length == 1">
              {{ age }}岁
            </div>
          </div>
          <!-- 下拉框 -->
          <el-dropdown-menu slot="dropdown" v-if="stuLists.length > 1">
            <div class="stuSwitchDiv">
              <div class="div">
                <div
                  class="stu-li"
                  v-for="(iten, index) in stuLists"
                  :key="index"
                >
                  <img :src="iten.headImg" alt="" />
                  <span>{{ iten.stuName }}</span>
                  <div class="switch-button-text" v-if="iten.isBinding == 1">
                    已选择
                  </div>
                  <div
                    class="switch-button"
                    v-else
                    @click="assignStudentFn(iten)"
                  >
                    切换
                  </div>
                </div>
              </div>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div class="nav-router">
        <div
          :class="navName == 'classcourse' ? 'router-active' : 'router-button'"
          @click="indexCenter"
        >
          <img src="@/assets/student/nav_imgs/index.png" alt="" />
          <p>首页</p>
        </div>
        <div
          :class="
            navName == 'homework-center' ? 'router-active' : 'router-button'
          "
          @click="homeworkCenter"
        >
          <img src="@/assets/student/nav_imgs/homework.png" alt="" />
          <p>我的作业</p>
        </div>
        <div
          :class="
            navName == 'notice-center' ? 'router-active' : 'router-button'
          "
          @click="noticeCenter"
        >
          <img src="@/assets/student/nav_imgs/notice.png" alt="" />
          <p>消息中心</p>
        </div>
        <div
          :class="navName == 'set-center' ? 'router-active' : 'router-button'"
          @click="setCenter"
        >
          <img src="@/assets/student/nav_imgs/set.png" alt="" />
          <p>设置中心</p>
        </div>
      </div>
      <div class="right-menu">
        <!-- 顶部navbar -->
        <el-dropdown
          class="avatar-container right-menu-item hover-effect"
          trigger="click"
        >
          <!-- 右边用户名和用户头像 -->
          <div class="avatar-wrapper">
            <!-- <img :src="userInfo.headImg" class="user-avatar" /> -->
            <span class="user-name">Hi,{{ userInfo.nickName }}</span>
            <i class="el-icon-caret-bottom" />
          </div>
          <!-- 下拉框 -->
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="settingFn">
              <span style="display: block">账号设置</span>
            </el-dropdown-item>
            <el-dropdown-item @click.native="logout">
              <span style="display: block">退出登录</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { logouts } from "@/api/hycooby/login.js";
import { assignStudent, stuList } from "@/api/student/index.js";
import { getUnReadNum } from "@/api/teacher/index.js";
import { mapGetters } from "vuex";
export default {
  activated() {
    this.$nextTick(() => {
      this.getUnReadNumFn();
    });
  },
  computed: {
    ...mapGetters(["sidebar", "userInfo", "student"]),
  },
  data() {
    return {
      messageStatus: true,
      messageNum: 0,
      curStu: "", //当前绑定的学生信息
      stuSwitchBool: false, //切换学生信息
      stuLists: "", //所有的学生信息
      navName: "classcourse",
      age: "", //年龄
      newUserInfo: null,
    };
  },
  watch: {
    student(newval) {
      this.curStu = newval;
      this.stuListFn();
    },
  },
  created() {
    this.$nextTick(() => {
      this.stuListFn();
      this.stuListFns();
    });
  },
  mounted() {
    this.$nextTick(() => {
      let route = this.$route.fullPath;
      let name = "";
      let first = 0;
      for (let index = 0; index < route.length; index++) {
        if (route[index] == "/") {
          first = index;
          name = route.slice(first + 1, route.length);
        }
      }
      if (
        name == "classcourse" ||
        name == "homework-center" ||
        name == "notice-center" ||
        name == "set-center"
      ) {
        this.navName = name;
      } else {
        this.navName = "classcourse";
      }
      this.curStu = this.userInfo.curStu;
    });
  },
  methods: {
    stuListFns() {
      stuList().then((ok) => {
        if (ok.code == "200" && ok.data.length > 0) {
          const curStu = localStorage.getItem("student");
          const account = localStorage.getItem("account");
          if (account == this.userInfo.userId) {
            if (
              curStu != "" &&
              curStu != "undefined" &&
              curStu != null &&
              this.userInfo.curStu.stuId == ""
            ) {
              assignStudent(curStu).then((ok) => {
                localStorage.setItem("student", curStu);
                this.$store.commit("user/SET_USER_INFO", ok.data);
                this.$store.commit("userdata/SET_STUDENT", ok.data.curStu);
                this.$store.commit("userdata/SET_STU_BUG", 1);
              });
            } else if (this.userInfo.curStu.stuId != "") {
              assignStudent(this.userInfo.curStu.stuId).then((ok) => {
                this.$store.commit("userdata/SET_STUDENT", ok.data.curStu);
              });
            } else {
              assignStudent(ok.data[0].stuId).then((ok) => {
                this.$store.commit("user/SET_USER_INFO", ok.data);
                this.$store.commit("userdata/SET_STUDENT", ok.data.curStu);
                this.$store.commit("userdata/SET_STU_BUG", 1);
              });
            }
          } else {
            if (this.userInfo.curStu.stuId != "") {
              assignStudent(this.userInfo.curStu.stuId).then((ok) => {
                this.$store.commit("user/SET_USER_INFO", ok.data);
                this.$store.commit("userdata/SET_STUDENT", ok.data.curStu);
              });
            } else {
              assignStudent(ok.data[0].stuId).then((ok) => {
                this.$store.commit("user/SET_USER_INFO", ok.data);
                this.$store.commit("userdata/SET_STUDENT", ok.data.curStu);
                this.$store.commit("userdata/SET_STU_BUG", 1);
              });
            }
          }
        }
      });
    },
    //点击首页
    indexCenter() {
      this.navName = "classcourse";
      this.$router.push("/class-center/index/classcourse");
      // location.reload();
    },
    //作业中心
    homeworkCenter() {
      // this.navName = "homework-center";
      // this.$router.push("/homework-center");
      this.$message.error("功能完善中，请前往app查看~");
    },
    //通知中心
    noticeCenter() {
      this.navName = "notice-center";
      this.$router.push("/notice-center");
    },
    //设置中心
    setCenter() {
      this.navName = "set-center";
      this.$router.push("/set-center");
      // this.$message.error("待开发");
    },
    //变更学员
    assignStudentFn(iten) {
      assignStudent(iten.stuId).then((ok) => {
        this.stuSwitchBool = false;
        this.curStu = ok.data;
        localStorage.setItem("account", this.curStu.userId);
        localStorage.setItem("student", this.curStu.curStu.stuId);
        this.$router.push("/class-center/index/classcourse");
        // 这将触发页面刷新
        location.reload();
      });
    },
    //请求学生信息
    stuListFn() {
      stuList().then((ok) => {
        this.$nextTick(() => {
          this.stuLists = ok.data;
          console.log(this.stuLists, "学生们");
          // this.$store.commit("userdata/SET_STU_STULIST", ok.data);
          for (let index = 0; index < this.stuLists.length; index++) {
            if (this.curStu.stuId == this.stuLists[index].stuId) {
              this.stuLists[index].isBinding = 1;
              this.age = this.stuLists[index].age;
            }
          }
        });
      });
    },
    //切换学生
    stuSwitch() {
      this.$nextTick(() => {
        this.stuSwitchBool = !this.stuSwitchBool;
        // 通过 JavaScript 添加或移除 .active 类来控制元素的显示和隐藏
        const element = document.querySelector(".stuSwitchDiv");
        if (this.stuSwitchBool == true) {
          element.classList.add("active");
        } else {
          element.classList.remove("active");
        }
      });
    },
    //获取未读消息数
    getUnReadNumFn() {
      getUnReadNum().then((ok) => {
        this.messageNum = ok.data;
      });
    },
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    async logout() {
      logouts().then((ok) => {
        if (ok.code == 200) {
          this.$store.dispatch("user/logout");
          this.$router.replace("/login");
        }
      });
    },
    openSetCenter() {
      this.$router.push("/notice-center");
    },
    settingFn() {
      this.$router.push("/set-center");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: #ffffff;
    color: #ff6a00;
}
/* 自定义滚动条样式 */
::-webkit-scrollbar {
  width: 0px;
  /* 设置滚动条宽度 */
}

:deep(.popper__arrow) {
  display: none;
}

:deep(.el-dropdown-menu) {
  padding: 0;
}

.popper__arrow {
  display: none;
}

#dropdown-menu-7702 {
  display: none;
}

.stuSwitchDiv {
  opacity: 1;
  transition: all 0.2s;
  position: absolute;
  top: 42px;
  left: -130px;
  max-height: 330px;
  overflow-y: scroll;
  z-index: 9;
  width: 300px;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 0px rgba(20, 21, 22, 0.06);
  border-radius: 24px;

  .div {
    margin: 14px 20px;

    .stu-li {
      width: 100%;
      height: 50px;
      margin: 10px 0;
      display: flex;
      position: relative;
      align-items: center;

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }

      span {
        margin-left: 10px;
        font-weight: 600;
        font-size: 14px;
        color: #723500;
      }

      .switch-button {
        width: 56px;
        position: absolute;
        right: 0;
        cursor: pointer;
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        font-weight: 600;
        color: #723500;
        text-align: center;
        background-color: #ffc600;
        border-radius: 12px;
      }

      .switch-button-text {
        position: absolute;
        right: 0;
        width: 56px;
        font-weight: 600;
        height: 24px;
        line-height: 24px;
        text-align: center;
        font-size: 12px;
        color: #723500;
        opacity: 0.6;
      }
    }
  }
}

.navbar {
  transition: all 0.2s;
  position: relative;
  background: #fff;

  .nav-top-center {
    width: 1200px;
    height: 100px;
    margin: auto;
    position: relative;
    display: flex;
    align-items: center;

    .left-logo {
      position: absolute;
      left: 0;
      width: 182px;
    }

    .student-info {
      position: absolute;
      transition: all 0.2s;
      left: 320px;
      width: 166px;
      height: 48px;
      background-color: #fef0e5;
      border-radius: 24px;
      border: solid 1px #f6d9bf;
      display: flex;
      align-items: center;

      .stu-info-img {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        margin: 0 10px 0 3px;
        background-color: #ffffff;
        border: solid 1px #ffffff;
        overflow: hidden;

        img {
          width: 100%;
        }
      }

      .stu-info-name {
        width: 48px;
        height: 16px;
        margin-right: 8px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        font-size: 14px;
        text-overflow: ellipsis;
        color: #723500;
      }

      .stu-info-change {
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }

        padding-left: 10px;
        border-left: 1px solid #dfcdbe;
        height: 12px;
        font-size: 12px;
        color: #723500;
        opacity: 0.6;
      }

      .stuSwitchDiv.active {
        opacity: 0;
        height: 0;
      }
    }

    .nav-router {
      width: 480px;
      position: absolute;
      left: 565px;
      display: flex;
      justify-content: space-between;

      .router-button {
        position: relative;

        &:hover {
          cursor: pointer;
          background-color: #f5f5f5;

          ::before {
            content: "";
            width: 25px;
            height: 25px;
            position: absolute;
            left: -25px;
            bottom: 0;
            background: radial-gradient(
              circle at 0 0,
              transparent 25px,
              #f5f5f5 25px
            );
          }

          ::after {
            content: "";
            width: 25px;
            height: 25px;
            background: radial-gradient(
              circle at 0 0,
              transparent 25px,
              #f5f5f5 25px
            );
            transform: rotate(90deg);
            position: absolute;
            right: -25px;
            bottom: 0;
          }
        }

        width: 120px;
        height: 80px;
        border-radius: 30px 30px 0 0;
        margin-top: 20px;
        text-align: center;

        img {
          width: 40px;
          margin: 5px 0 0 0;
        }

        p {
          text-align: center;
          font-size: 14px;
          color: #333333;
        }
      }

      .router-active {
        position: relative;
        cursor: pointer;
        background-color: #f5f5f5;
        ::before {
          content: "";
          width: 25px;
          height: 25px;
          // background-color: #ff6a00;
          position: absolute;
          left: -25px;
          bottom: 0;
          background: radial-gradient(
            circle at 0 0,
            transparent 25px,
            #f5f5f5 25px
          );
        }

        ::after {
          content: "";
          width: 25px;
          height: 25px;
          background: radial-gradient(
            circle at 0 0,
            transparent 25px,
            #f5f5f5 25px
          );
          transform: rotate(90deg);
          position: absolute;
          right: -25px;
          bottom: 0;
        }

        width: 120px;
        height: 80px;
        border-radius: 30px 30px 0 0;
        margin-top: 20px;
        text-align: center;

        img {
          width: 40px;
          margin: 5px 0 0 0;
        }

        p {
          font-weight: 600;
          text-align: center;
          font-size: 14px;
          color: #333333;
        }
      }
    }
  }

  .hamburger-container {
    line-height: 100px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    width: 100px;
    position: absolute;
    right: 0;
    height: 100%;
    line-height: 100px;
    display: flex;
    align-items: center;

    .user-message {
      width: 33px;
      height: 33px;
      cursor: pointer;
      margin-right: 10px;
    }

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        position: relative;
        display: flex;
        align-items: center;

        .user-avatar {
          cursor: pointer;
          width: 30px;
          height: 30px;
          border-radius: 30px;
        }

        .user-name {
          color: #333333;
          font-size: 16px;
          margin-left: 6px;
          width: 82px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -8px;
          top: 43px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
