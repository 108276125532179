<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <!-- 是否缓存路由页面  include-->
      <keep-alive :include="cachedViews">
        <!-- Vue会复用相同组件，对于路由有多个子路由来说，
          当在子路由来回切换时，会导致页面不刷新的问题，
          因为不再执行created和mounted这些钩子函数。
          <router-view :key='key'/>中的key即可解决这个问题 -->
        <router-view :key="key" />
      </keep-alive>
    </transition>
    
  </section>
</template>

<script>
export default {
  name: "AppMain",
  computed: {
    // 判断是否保存路由数据的数组
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    // 当前的路由
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-main {
  /* 50= navbar  50  */
  min-height: calc(100vh - 100px);
  width: 1200px;
  margin: auto;
  // border: 2px solid #000;
  position: relative;
  // overflow: hidden;
  // background: #f5f5f5;
}

.fixed-header + .app-main {
  padding-top: 100px;
}

.hasTagsView {
  .app-main {
    /* 84 = navbar + tags-view = 64 + 34 */
    min-height: calc(100vh - 134px);
  }

  .fixed-header + .app-main {
    padding-top: 134px;
  }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    // padding-right: 15px;
  }
}
</style>
