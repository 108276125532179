//处理浏览器信息
export const browser = () => {

    let userAgentStr = navigator.userAgent
    let data = {
        manufacturer: navigator.vendor,//产商
        // osName : navigator.platform,
        deviceModel: ""//设备型号
    }
    if (userAgentStr.indexOf('QQBrowser/') > 0) {
        data.deviceModel = 'QQBrowswe'
    } else if (userAgentStr.indexOf('Opera/') > 0) {
        data.deviceModel = 'Opera'
    } else if (userAgentStr.indexOf('360SE') > 0) {
        data.deviceModel = '360'
    } else if (userAgentStr.indexOf('Firefox/') > 0) {
        data.deviceModel = 'Firefox'
    } else if (userAgentStr.indexOf('MSIE/') > 0) {
        data.deviceModel = 'IE'
    } else if (userAgentStr.indexOf('Chrome/') > 0) {
        data.deviceModel = 'Chrome'
    } else if (userAgentStr.indexOf('Safari/') > 0) {
        data.deviceModel = 'Safari'
    }
    return data
}