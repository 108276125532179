import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vue from 'vue';
// 全局样式  重置css样式表
import './styles/normalize.css';
// vue-admin-element 样式表
// import './styles/index.scss';
import './styles/hy-element-ui.scss';

import App from './App.vue';
import './permission';
import router from './router';
import store from './store';

// 引入 directives
import '@/utils/common/directives.js';

Vue.use(ElementUI);

// 全局注册图片组件 用来在sidebar使用 自定义的图片
import ImgIcon from '@/components/ImgIcon/index.vue';
Vue.component('img-icon', ImgIcon)
// 全局注册dialog
import DialogPc from '@/components/dialog/dialog-pc.js';
import Dialog from '@/utils/common/dialog.js';
Vue.prototype.$dialog = Dialog;
Vue.prototype.$dialogPc = DialogPc;
// 导入全局弹框提示组件
import PopupMessage from '@/views/teacher/course-history/component/bulletBox.vue';
// 全局引入注册过滤方法
import * as filters from '@/utils/common/filters.js';
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.config.ignoredElements = ['wx-open-launch-app']

Vue.config.productionTip = false
// 禁用用户通过插件查看vuex数据
const isDebug_mode = !(process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') //关闭开发工具
Vue.config.productionTip = false;
Vue.config.debug = isDebug_mode
Vue.config.devtools = isDebug_mode
Vue.config.productionTip = isDebug_mode
// 这个图片后期要换
// const loadimage = require('./assets/common/loading.png')
// const errorimage = require('./assets/common/logo.png')
// 图片懒加载
// Vue.use(VueLazyload, {
//   preLoad: 1.3,
//   error: errorimage,
//   loading: loadimage,
//   attempt: 1
// })
// 注册全局组件
Vue.component('PopupMessage', PopupMessage);
Vue.prototype.TEduBoard = window.TEduBoard;
Vue.prototype.$EventBus = new Vue();

new Vue({
  beforeCreate() {
    // 添加事件总线
    // 在$bus身上有，$on和$emit  $on可以用来接收数据   $emit可以用来发送数据
    Vue.prototype.$bus = this;
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')
