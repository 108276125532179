<template>
  <div class="img-box">
    <!-- 图片路径拼接 要用require的方式才能找到图片 -->
    <img :style="{width:width + 'px',height:height + 'px'}" :src="require('@/assets/common/sidebar/' + imgClass + '.png')" alt="">
  </div>
</template>

<script>
export default {
  props: {
    imgClass: {
      type: String,
      default: ''
    },
    width:{
      type: String,
    },
    height:{
      type: String,
    }
  }
}
</script>

<style scoped lang="scss">
.img-box {
  display: inline-block;

  img {
    display: block;
  }
}
</style>