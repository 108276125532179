<script>

export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    img:{
      type:String,
      default:''
    }
  },
  render(h, context) {
    const { icon, title,img } = context.props
    const vnodes = []
    if (icon) {
      // 使用element icon 图标
      if (icon.includes('el-icon')) {
        vnodes.push(<i class={[icon, 'sub-el-icon']} />)
      }
    }
    // 自定义图片 图标
    if(img){
      // 引入全局注册的图片组件
      vnodes.push(<img-icon img-class={img}/>)
    }

    if (title) {
      vnodes.push(<span slot='title'>{(title)}</span>)
    }
    return vnodes
  }
}
</script>

<style scoped lang="scss">
.sub-el-icon {
  color: currentColor;
  width: 1em;
  height: 1em;
}

</style>
