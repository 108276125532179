<template>
  <div class="app-wrapper">
    <!-- 顶部导航 -->
    <div class="main-header">
      <div class="header-body">
        <navbar @routerChange="routerChangeFn" />
      </div>

    </div>
    <div class="main-container">

      <div class="main-body">
        <div class="sideBar" v-if="isShowSideBar">
          <div class="title">{{ sideBar.meta.title }}</div>
          <div class="list">
            <div class="item" @click="goLink(sideBar, item)" :class="{ active: $route.name == item.name }" v-for="(item, index) in sideBarList"
              :key="index">
              <img-icon :img-class="$route.name == item.name ? item.meta.activeImg :item.meta.img" width="20" height="20"/>
              <span >{{ item.meta.title }}</span>
            </div>
          </div>
        </div>
        <app-main class="app-main-body" />
      </div>

    </div>
    <div class="issue-box" @click="openIssue">
      <img src="@/assets/common/tea-issue.png" alt="">
      <p class="issue">常见问题</p>
    </div>
    <el-dialog title="常见问题" :visible.sync="issueBool" width="600px" class="issue-pop-up" :before-close="handleClose">
      <issue :issueData="issueData"></issue>
    </el-dialog>
    <el-backtop target=".app-wrapper .main-container" :bottom="150" :right="100" :visibility-height="100">
      <img src="@/assets/hycooby/index/backTop.png" alt="" srcset="">
    </el-backtop>
  </div>
</template>

<script>
// 由components中的index.js 统一引入四个组件
import { askedQuestion } from "@/api/teacher/index.js";
import path from 'path';
import { mapState } from "vuex";
import { AppMain, Navbar } from "./components";
import issue from "./components/issue.vue";
export default {
  name: "Layout",
  data() {
    return {
      issueBool: false,
      issueData: '',
      isShowSideBar: false,
      sideBar: null
    };
  },
  components: {
    AppMain,
    Navbar,
    issue,
  },
  computed: {
    ...mapState({
      sidebar: (state) => state.app.sidebar,
      device: (state) => state.app.device,
    }),
    sideBarList() {     
      return this.sideBar.children.filter(item => !item.meta.childrenHidden)
    },
  },

  methods: {

    goLink(sideBar, item) {
      let url = path.resolve(sideBar.path, item.path)
      this.$router.push(url)
    },
    routerChangeFn(isShowSideBar, routerItem) {
      // 是否显示侧边栏
      if (isShowSideBar) {
        this.isShowSideBar = true;
        this.sideBar = routerItem
        // console.log(this.$route,routerItem);
      } else {
        this.isShowSideBar = false;
        this.sideBar = null;
      }
    },
    handleClose() {
      this.issueBool = false
    },
    //查看常见问题
    openIssue() {
      askedQuestion().then((ok) => {
        this.issueData = ok.data
      })
      this.issueBool = true
    },
  },
};
</script>

<style lang="scss" scoped>
// 因为#app 这个css样式不生效 所以把这个主体的高度调成100vh
.app-wrapper {
  position: relative;
  height: 100vh;
  width: 100%;
  background: #f5f5f5;

  .main-header {
    width: 100%;
    background: #fff;

    .header-body {
      margin: 0 auto;
      width: 1200px;
  
    }
  }

  .main-container {
   
    height: calc(100vh - 100px);
    overflow-y: auto;
    .main-body {
      width: 1200px;
      margin: 0 auto;
      display: flex;

      .sideBar {
        width: 200px;
        background-color: #ffffff;
        border-radius: 30px;
        padding: 40px 15px;
        box-sizing: border-box;
        margin-right: 28px;
        flex-shrink: 0;
        height: 480px;
        margin-top: 30px;
        text-align: center;
       
        .title {
          margin-bottom: 50px;
          font-weight: bold;
          font-size: 18px;
          color: #333333;
        }

        .item {
          width: 170px;
          height: 60px;
          line-height: 60px;
          font-size: 16px;
          color: #333333;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            display: block;
            height: 100%;
            margin-left: 7px;
          }

          &.active {
            background-color: #fef0e5;
            color: #ff6a00;
            border-radius: 30px;
          }
        }
      }

      .app-main-body {
        flex: 1;
      }
    }
  }
}

:deep(.el-dialog) {
  border-radius: 14px;
  overflow: hidden;
}

:deep(.el-dialog__body) {
  padding: 10px 20px 30px;

}

:deep(.el-dialog__header) {
  padding: 20px;
  font-size: 18px;
  font-weight: 700;
  // background-image: linear-gradient(90deg,
  //     #fff1de 0%,
  //     #ffffff 100%),
  //   linear-gradient(#fd9708,
  //     #fd9708);
  background-blend-mode: normal,
    normal;
  border-radius: 30px 30px 0px 0px;
}

:deep(.el-dialog__headerbtn) {
  font-weight: 18px !important;
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}



.issue-box {
  position: fixed;
  z-index: 999;
  bottom: 193px;
  right: 0;
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 80px;
	height: 46px;
	background-color: #ffc600;
	border-radius: 23px 0px 0px 23px;
  img {
    width: 30px;
    margin: 0 5px 0 8px;
  }

  p {
    font-size: 14px;
    color: #723500;
  }
}
</style>
