import dialog from '@/views/mobileView/examination/base/dialog.vue'
import Vue from 'vue'
const DialogConstructor = Vue.extend(dialog)

let removeConfirm = event =>{
    let child = document.querySelector('body');
    let childList = child.querySelectorAll('.dialog');
    for (let i = 0; i < childList.length; i++) {
        child.removeChild(childList[i])
    }
}

DialogConstructor.prototype.msgClose = function (state) {
        if (this.callback) {
            this.callback(state)
        }
            this.visible = false;
            removeConfirm();
};

const Dialog = (options = {}) => {
    let instance = new DialogConstructor().$mount(document.createElement('div'));
    instance.visible = true;
    instance.message = options.message;
    instance.title = options.title;
    instance.cancelText = options.cancelText || '取消';
    instance.confirmText = options.confirmText || '退出';
    instance.hideCancel = options.hideCancel || false;
    instance.callback = options.callback;
    document.body.appendChild(instance.$el);
    return instance
};

export default Dialog