const state = {
  visitedViews: [], // tag的值
  cachedViews: []  // 缓存起来的tag
}

const mutations = {
  ADD_VISITED_VIEW: (state, view) => {
    if (state.visitedViews.some(v => v.path === view.path)) return
    state.visitedViews.push(
      Object.assign({}, view, {
        title: view.meta.title || 'no-name'
      })
    )
  },
  ADD_CACHED_VIEW: (state, view) => {
    if (state.cachedViews.includes(view.name)) return
    if (!view.meta.noCache) {
      state.cachedViews.push(view.name)
    }
  },

  DEL_VISITED_VIEW: (state, view) => {
    for (const [i, v] of state.visitedViews.entries()) {
      if (v.path === view.path) {
        state.visitedViews.splice(i, 1)
        break
      }
    }
  },
  // 从cachedViews删除当前tag的值
  DEL_CACHED_VIEW: (state, view) => {
    // 这个tag的值是否在cachedViews数组中 获取下标
    const index = state.cachedViews.indexOf(view.name)
    // 在这个数组中 并且根据下标删除当前项
    index > -1 && state.cachedViews.splice(index, 1)
  },
  // 新的tag页面 = tag数组中过滤出 有固定tag的值 和当前选中的值
  DEL_OTHERS_VISITED_VIEWS: (state, view) => {
    state.visitedViews = state.visitedViews.filter(v => {
      return v.meta.affix || v.path === view.path
    })
  },
  // tag缓存页面 = 
  DEL_OTHERS_CACHED_VIEWS: (state, view) => {
    // 找到当前选中tag的下标
    const index = state.cachedViews.indexOf(view.name)
    if (index > -1) {
      // 找到下标 tag缓存数组 = slice截取出当前tag的唯一值
      state.cachedViews = state.cachedViews.slice(index, index + 1)
    } else {
      // if index = -1, there is no cached tags
      // 如果没找到下标 tag缓存数组就为空
      state.cachedViews = []
    }
  },
  //  过滤出固定tag
  DEL_ALL_VISITED_VIEWS: state => {
    // keep affix tags
    const affixTags = state.visitedViews.filter(tag => tag.meta.affix)
    state.visitedViews = affixTags
  },
  // 清空缓存
  DEL_ALL_CACHED_VIEWS: state => {
    state.cachedViews = []
  },
  // 循环tag数组 如果path路由地址相同 
  // 就将传进来的当前tag 深拷贝一份 重新赋值给 匹配到的v这一项的内容
  UPDATE_VISITED_VIEW: (state, view) => {
    for (let v of state.visitedViews) {
      if (v.path === view.path) {
        v = Object.assign(v, view)
        break
      }
    }
  }
}

const actions = {
  // 添加缓存和tag
  addView({ dispatch }, view) {
    dispatch('addVisitedView', view)
    dispatch('addCachedView', view)
  },
  // 只添加tag
  addVisitedView({ commit }, view) {
    commit('ADD_VISITED_VIEW', view)
  },
  // 只添加缓存
  addCachedView({ commit }, view) {
    commit('ADD_CACHED_VIEW', view)
  },
  // 将当前缓存页面的tag从缓存数组和显示tag标签的数组都删除
  delView({ dispatch, state }, view) {
    return new Promise(resolve => {
      dispatch('delVisitedView', view)
      dispatch('delCachedView', view)
      resolve({
        visitedViews: [...state.visitedViews],
        cachedViews: [...state.cachedViews]
      })
    })
  },
  delVisitedView({ commit, state }, view) {
    return new Promise(resolve => {
      commit('DEL_VISITED_VIEW', view)
      resolve([...state.visitedViews])
    })
  },
  // 删除当前缓存的tag从cachedViews删除
  // 在返回删除后剩余的tag的值
  delCachedView({ commit, state }, view) {
    return new Promise(resolve => {
      commit('DEL_CACHED_VIEW', view)
      resolve([...state.cachedViews])
    })
  },
  // 删除其他的值
  delOthersViews({ dispatch, state }, view) {
    return new Promise(resolve => {
      // 删除缓存和tag
      dispatch('delOthersVisitedViews', view)
      dispatch('delOthersCachedViews', view)
      resolve({
        visitedViews: [...state.visitedViews],
        cachedViews: [...state.cachedViews]
      })
    })
  },
  // 删除除了当前tag信息的其他值的tag
  delOthersVisitedViews({ commit, state }, view) {
    return new Promise(resolve => {
      commit('DEL_OTHERS_VISITED_VIEWS', view)
      resolve([...state.visitedViews])
    })
  },
  // 删除除了当前tag信息的缓存里其他的值
  delOthersCachedViews({ commit, state }, view) {
    return new Promise(resolve => {
      commit('DEL_OTHERS_CACHED_VIEWS', view)
      resolve([...state.cachedViews])
    })
  },
  // 删除所有的缓存和tag
  delAllViews({ dispatch, state }, view) {
    return new Promise(resolve => {
      dispatch('delAllVisitedViews', view)
      dispatch('delAllCachedViews', view)
      resolve({
        visitedViews: [...state.visitedViews],
        cachedViews: [...state.cachedViews]
      })
    })
  },
  delAllVisitedViews({ commit, state }) {
    return new Promise(resolve => {
      commit('DEL_ALL_VISITED_VIEWS')
      resolve([...state.visitedViews])
    })
  },
  delAllCachedViews({ commit, state }) {
    return new Promise(resolve => {
      commit('DEL_ALL_CACHED_VIEWS')
      resolve([...state.cachedViews])
    })
  },
  // 更新tags 传入当前路由信息
  updateVisitedView({ commit }, view) {
    commit('UPDATE_VISITED_VIEW', view)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
