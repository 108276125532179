<template>
  <div :class="classObj" class="app-wrapper">
    <div :class="{ hasTagsView: needTagsView }" class="main-container">
      <div :class="{ 'fixed-header': fixedHeader }">
        <!-- 顶部导航 -->
        <navbar />
        <!-- tag栏 -->
        <tags-view v-if="needTagsView" />
      </div>
      <app-main />

      <div class="issue-box" @click="openIssue">
        <img src="@/assets/student/nav_imgs/issue.png" alt="">
        <p class="issue">常见问题</p>
      </div>

    </div>
    <!-- <router-view :key="$route.fullPath"></router-view> -->
    <el-dialog title="常见问题" :visible.sync="issueBool" width="600px" class="issue-pop-up" :before-close="handleClose">
      <issue :issueData="issueData"></issue>
    </el-dialog>
    
    <el-backtop target=".app-wrapper" :bottom="150" :right="100" :visibility-height="100">
      <img src="@/assets/hycooby/index/backTop.png" alt="" srcset="">
    </el-backtop>
  </div>
</template>

<script>
// 由components中的index.js 统一引入四个组件
import { askedQuestion } from "@/api/student/index.js";
import { mapState } from "vuex";
import { AppMain, Navbar, TagsView } from "./components";
import issue from "./components/issue.vue";
export default {
  name: "Layout",
  components: {
    AppMain,
    Navbar,
    issue,
    TagsView,
  },
  data() {
    return {
      issueBool: false,
      issueData: '',
    }
  },
  computed: {
    ...mapState({
      sidebar: (state) => state.app.sidebar,
      device: (state) => state.app.device,
      needTagsView: (state) => state.settings.tagsView,
      fixedHeader: (state) => state.settings.fixedHeader,
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
      };
    },
  },
  methods: {
    handleClose() {
      this.issueBool = false
    },
    //查看常见问题
    openIssue() {
      askedQuestion().then((ok) => {
        this.issueData = ok.data
      })
      this.issueBool = true
    },
    handleClickOutside() {
      this.$store.dispatch("app/closeSideBar", { withoutAnimation: false });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.module.scss";

// 因为#app 这个css样式不生效 所以把这个主体的高度调成100vh
.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}
:deep(.el-dialog){
  border-radius: 30px;
  overflow: hidden;
}

:deep(.el-dialog__header){
  padding: 20px;
  font-size: 18px;
  font-weight: 700;
  background-image: linear-gradient(90deg,
      #fff1de 0%,
      #ffffff 100%),
    linear-gradient(#fd9708,
      #fd9708);
  background-blend-mode: normal,
    normal;
  border-radius: 30px 30px 0px 0px;
}

:deep(.el-dialog__headerbtn){
  font-weight: 18px !important;
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: 100%;
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.mobile .fixed-header {
  width: 100%;
}

.main-container {
  margin-left: 0 !important;
  background: #f5f5f5;
}

.issue-box {
  position: fixed;
  z-index: 999;
  bottom: 70px;
  right: 0;
  width: 80px;
  height: 46px;
  background-color: #ffc600;
  border-radius: 23px 0px 0px 23px;
  color: #fff;
  color: #723500;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    margin: 0 5px 0 8px;
  }

  p {
    width: 2rem;
  }
}
</style>
