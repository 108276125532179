<template>
  <div class="navbar">
    <div class="left-menu">
      <div class="logo">
        <img src="@/assets/student/nav_imgs/LOGO.png" alt="">
      </div>
      <div class="sidebar">
        <div class="item" :class="{ active: activeIndex == index }" @click="routeFn(route, index)"
          v-for="route, index in routerList" :key="route.path">
          <img-icon :img-class="route.meta.img" width="44" height="44" />
          <span>{{ route.meta.title }}</span>
        </div>
      </div>
    </div>
    <div class="right-menu">
      <img v-if="messageStatus" src="@/assets/common/message-active.png" class="user-message" @click="openSetCenter"
        alt="" />
      <img v-else src="@/assets/common/message.png" @click="openSetCenter" class="user-message" alt="" />
      <!-- 顶部navbar -->
      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <!-- 右边用户名和用户头像 -->
        <div class="avatar-wrapper">
          <img :src="userInfo.headImg" class="user-avatar" />
          <span class="user-name">{{ userInfo.nickName }}</span>
          <i class="el-icon-caret-bottom" />
        </div>
        <!-- 下拉框 -->
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="settingFn">
            <span style="display: block">账号设置</span>
          </el-dropdown-item>
          <el-dropdown-item @click.native="logout">
            <span style="display: block">退出</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { tchLogouts } from "@/api/hycooby/login";
import { getUnReadNum } from "@/api/teacher/index.js";
import { mapGetters } from "vuex";
export default {
  components: {
  },
  watch: {
    // 监听路由变化
    '$route': 'handleRouteChange'
  },
  mounted() {
    this.$nextTick(() => {
      // console.log(this.userInfo);
      this.getUnReadNumFn();
      // 一开始就监听路由变化 改变路由的索引
      this.handleRouteChange(this.$route);
    });
  },
  computed: {
    ...mapGetters(["permission_routes", "sidebar", "userInfo"]),
    routerList() {
      let router = this.permission_routes
      return router.filter(item => {
        return !item.hidden;
      });
    },
    // 顶部导航栏 不显示 但是 左侧导航栏要显示  过滤出  需要显示左侧路由的数据
    routerListSilderbar() {

      let router = this.permission_routes;
      let routerList = []
      router.forEach(i => {     
        if (i.meta) {
          if(i.meta.showSildeBar){
             routerList.push(i)
          }
         
        }
      });   
      return routerList
    }
  },
  data() {
    return {
      messageStatus: false,
      activeIndex: 0
    };
  },
  methods: {
    // 监听路由变化
    handleRouteChange(route) {
      // console.log(route);
      const index = this.routerList.findIndex(item => route.fullPath.indexOf(this.toLowerCaseExceptHyphen(item.name)) > -1);
      // 是否在可显示的路由中
      if (index !== -1) {

        // 如果子路由超过一个  显示侧边栏
        if (this.routerList[index].children.length > 1) {
          if (route.meta.childrenHidden) {
            this.$emit('routerChange', false)
          } else {
            this.$emit('routerChange', true, this.routerList[index])
          }
        } else {
          // 否则不显示
          this.$emit('routerChange', false)
        }
      } else {
        // 获取第一项路由的信息
        let matchedFirstRoute = route.matched[0].meta;
        // 不在顶部导航栏中 但是左侧需要显示导航栏 
        if (matchedFirstRoute.showSildeBar) {
          const index = this.routerListSilderbar.findIndex(item => route.fullPath.indexOf(this.toLowerCaseExceptHyphen(item.name)) > -1);
          this.$emit('routerChange', true, this.routerListSilderbar[index])
        } else {
          this.$emit('routerChange', false, route)
        }
      }
      this.activeIndex = index;
    },
    toLowerCaseExceptHyphen(str) {
      return str.replace(/[A-Z]/g, function (char) {
        return char.toLowerCase();
      });
    },
    routeFn(item) {
      this.$router.push(item.path);
    },
    //获取消息未读数
    getUnReadNumFn() {
      getUnReadNum().then((ok) => {
        if (ok.data.data > 0) {
          this.messageStatus = true;
        } else {
          this.messageStatus = false;
        }
      });
    },
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    async logout() {
      tchLogouts().then(res => {
        this.$store.dispatch("user/logout");
        this.$router.replace("/login");
        this.$message.success("退出登入成功！");
      })

    },
    openSetCenter() {
      this.$router.push("/notice-center");
    },
    settingFn() {
      this.$router.push("/set-center");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: #ffffff;
    color: #ff6a00;
}
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;

  .left-menu {
    height: 100%;
    display: flex;
    align-items: center;

    .logo {
      width: 182px;
      height: auto;
      margin-right: 80px;
    }

    .sidebar {
      display: flex;
      align-items: flex-end;
      height: 100%;

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 80px;
        width: 120px;
        cursor: pointer;
        color: #333333;
        font-size: 14px;

        &.active {
          background: #f5f5f5;
          position: relative;
          border-top-left-radius: 30px;
          border-top-right-radius: 30px;

          &::before {
            content: "";
            width: 25px;
            height: 25px;
            // background-color: #ff6a00;
            position: absolute;
            left: -25px;
            bottom: 0;
            background: radial-gradient(circle at 0 0,
                transparent 25px,
                #f5f5f5 25px);
          }

          &::after {
            content: "";
            width: 25px;
            height: 25px;
            background: radial-gradient(circle at 0 0,
                transparent 25px,
                #f5f5f5 25px);
            transform: rotate(90deg);
            position: absolute;
            right: -25px;
            bottom: 0;
          }
        }
      }
    }
  }

  .right-menu {
    height: 100%;
    line-height: 64px;
    display: flex;
    align-items: center;

    .user-message {
      width: 18px;
      height: 18px;
      cursor: pointer;
      margin-right: 10px;
    }

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        // &:hover {
        //   background: rgba(0, 0, 0, .025)
        // }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        // margin-top: 5px;
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;

        .user-avatar {
          cursor: pointer;
          width: 30px;
          height: 30px;
          border-radius: 30px;
        }

        .user-name {
          color: #333333;
          font-size: 16px;
          margin-left: 6px;
          width: 66px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -8px;
          // top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
