
const state = {
    showHomeWorkDialog:false, // 跳到详情页 显示作业模式
    showCorrectDialog:false, // 跳到详情页 显示批改模式
    showFeedBackDialog:false, // 跳到详情页 显示自由批改模式
    showCloudDialog:false, // 跳到详情页 显示云端资源模式
};

const mutations = {
    setShowHomeWork(state, data) {
        state.showHomeWorkDialog = data;
    },
    setShowCorrect(state, data) {
        state.showCorrectDialog = data;
    },
    setShowFeedBack(state, data) {
        state.showFeedBackDialog = data;
    },
    setShowCloud(state, data){
        state.showCloudDialog = data;
    }
};

const actions = {
  
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
