<template>
  <div class="dialog">
    <div class="shadow" :class="visible ? 'active' : ''"></div>
    <div class="dialog-box">
      <div class="dialog-title">{{ title }}</div>
      <div class="dialog-main">{{ message }}</div>
      <div class="dialog-foot">
        <div class="dialog-cancel" v-if="!hideCancel" @click="msgClose('cancel')">
          {{ cancelText }}
        </div>
        <div class="dialog-confirm" @click="msgClose('confirm')">
          {{ confirmText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// this.$dialog({
//   confirmText: "确定",  // 确定按钮文字
//   cancelText:"取消",    // 取消按钮文字
//   title: "提交",       // 标题文字
//   hideCancel:true,     // 隐藏取消按钮
//   message: "您未答完题目,确定要提交吗？",  // 主体文字
//   callback: (res) => {  // 回调函数
//     // 点击的是确认按钮
//     if (res == "confirm") {
//       console.log("点击了确定");
//     }
//     if(res == "cancel"){
//       console.log("点击了取消");
//     }
//   },
// });

export default {
  data() {
    return {
      title: "",
      message: "",
      visible: false,
      confirmText: "",
      cancelText: "",
      hideCancel:false
    };
  },
  watch: {
    // 禁止页面滚动
    visible(val) {
      // 通过添加和删除class类名的方式 来禁止页面滑动
      if (val) {
        document.body.classList.add("overflow");
      } else {
        document.body.classList.remove("overflow");
      }
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.dialog {
  .shadow {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: none;
    z-index: 99;
    &.active {
      display: block;
    }
  }
  .dialog-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 71.911vw;
    border-radius: 5vw;
    background: #ffffff;
    text-align: center;
    transition: all 0.5s;
    z-index: 99;
    .dialog-title {
      font-size: 4.533vw;
      color: #000000;
      margin-bottom: 5.067vw;
      margin-top: 5.689vw;
      box-sizing: border-box;
      padding: 0 4.889vw;
    }
    .dialog-main {
      box-sizing: border-box;
      padding: 0 4.889vw;
      color: #333333;
      font-size: 3.467vw;
      line-height: 4.844vw;
      margin-bottom: 6.469vw;
    }
    .dialog-foot {
      border-top: 1px solid #d8dadb;
      display: flex;
      .dialog-confirm,
      .dialog-cancel {
        flex: 1;
        text-align: center;
        height: 11.822vw;
        line-height: 11.822vw;
        font-size: 4.533vw;
        color: #333333;
      }
      .dialog-confirm {
        color: #ff3b30;
      }
      .dialog-cancel{
        border-right: 1px solid #d8dadb;
      }
    }
  }
}
</style>