<template>
    <div class="issue-detail">
        <div class="issue-box" v-for="text, index in textArr" :key="index">
            <p class="title">{{ text.issue }}</p>
            <p class="contain" v-html="text.solution"></p>
        </div>
    </div>
</template>

<script>
export default {
    props: ['issueData'],
    data() {
        return {
            textArr: ''
        }
    },
    watch: {
        issueData() {
            this.textArr = this.issueData
        }
    }
}
</script>

<style lang="scss" scoped>
.issue-detail {
    width: 100%;
    height: 330px;
    overflow-y: auto;

    .title {
        margin-bottom: 6px;
        color: #000;
    }

    .contain {
        margin-bottom: 30px;
    }
}
</style>