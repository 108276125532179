

const TokenKey = 'hycooby_token'
const RefreshTokenKey = 'hycooby_refreshToken'
const deviceIdKey = 'deviceId'

export function getToken() {
  // return Cookies.get(TokenKey)
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
  // return Cookies.set(TokenKey, token)
  return localStorage.setItem(TokenKey, token)

}

export function removeToken() {
  // return Cookies.remove(TokenKey)
  return localStorage.removeItem(TokenKey)

}

export function getRefreshToken() {
  // return Cookies.get(TokenKey)
  return localStorage.getItem(RefreshTokenKey)
}

export function setRefreshToken(token) {
  // return Cookies.set(TokenKey, token)
  return localStorage.setItem(RefreshTokenKey, token)

}

export function removeRefreshToken() {
  // return Cookies.remove(TokenKey)
  return localStorage.removeItem(RefreshTokenKey)

}

export function getDeviceId() {
  return localStorage.getItem(deviceIdKey)
}