import request from '@/utils/request.js'
//查询当前用户下学员列表
export const getToken = (data) => {
  return request({
    url: '/api-user/rtc/getToken',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data"
    }
  })
}
//获取im班级聊天室id
export const findChatRoomId = (data) => {
  return request({
    url: '/api-user/study/findChatRoomId',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data"
    }
  })
}
//获取学生im token
export const getImToken = (data) => {
  return request({
    url: '/api-user/im/getToken',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data"
    }
  })
}
//获取rtc相关App的信息
export const appInfoStu = (data) => {
  return request({
    url: '/api-user/rtc/appInfo',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data"
    }
  })
}
// 查询班级聊天群组信
export const classChatGroupStu = (data) => {
  return request({
    url: '/api-user/im/classChatGroup',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data"
    }
  })}

  // 获取直播间拉流地址
  export const liveStreamUrl = (data) => {
    return request({
      url: '/api-user/rtc/liveStreamUrl',
      method: 'post',
      data,
      headers: {
          'content-type': "multipart/form-data"
        }
    })
  }

  // 学生给老师打分
  export const addScore = (data) => {
    return request({
      url: '/api-user/lesson/addScore',
      method: 'post',
      data,
      headers: {
          'content-type': "multipart/form-data"
        }
    })
  }
  // 课堂结束详情页
  export const finishDetail = (data) => {
    return request({
      url: '/api-user/lesson/finishDetail',
      method: 'post',
      data,
      headers: {
          'content-type': "multipart/form-data"
        }
    })
  }
  // 基础信息（未登录-通用接口）
  export const baseInfo = (data) => {
    return request({
      url: '/api-user/common/baseInfo',
      method: 'post',
      data,
      headers: {
          'content-type': "multipart/form-data"
        }
    })
  }

  // 修改学生课程状态
  export const updateStuLesson = (data) => {
    return request({
      url: '/api-user/study/updateStuLesson',
      method: 'post',
      data,
      headers: {
          'content-type': "multipart/form-data"
        }
    })
  }

  //快捷回复列表 /api-user/im/qrList
  export const qrList = (data) => {
    return request({
      url: '/api-user/im/qrList',
      method: 'post',
      data,
      headers: {
          'content-type': "multipart/form-data"
        }
    })
  }

  // 查询当前课堂是否可用 /api-user/study/checkLesson
  export const checkLesson = (data) => {
    return request({
      url: '/api-user/study/checkLesson',
      method: 'post',
      data,
      headers: {
          'content-type': "multipart/form-data"
        }
    })
  }

  // 客户端错误日志上传 /api-user/im/errorLog
  export const errorLog = (data) => {
    return request({
      url: '/api-user/im/errorLog',
      method: 'post',
      data,
      headers: {
          'content-type': "multipart/form-data"
        }
    })
  }

  // 重新生成ImToken /api-user/im/retrieveToken
  export const retrieveToken = (data) => {
    return request({
      url: '/api-user/im/retrieveToken',
      method: 'post',
      data,
      headers: {
          'content-type': "multipart/form-data"
        }
    })
  }

  // 申请加入聊天群 /api-user/study/joinChatGroup
  export const joinChatGroup = (data) => {
    return request({
      url: '/api-user/study/joinChatGroup',
      method: 'post',
      data,
      headers: {
          'content-type': "multipart/form-data"
        }
    })
  }
    // 强制进入RTC房间（腾讯RTC服务）
    export const forceJoinRoom = (data) => {
      return request({
        url: '/api-user/rtc/forceJoinRoom',
        method: 'post',
        data,
        headers: {
            'content-type': "multipart/form-data"
          }
      })
    }
    // 学员被踢出教室回调消息通知 /api-user/study/stuKickOutCallBack
    export const stuKickOutCallBack = (data) => {
      return request({
        url: '/api-user/study/stuKickOutCallBack',
        method: 'post',
        data,
        headers: {
            'content-type': "multipart/form-data"
          }
      })
    }