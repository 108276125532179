const state = {
  maillistbool: false,
  classroombool: false,
  classinforbool: false,
  changestate: 1,
  course: false,
  materials: false,
  homework: false,
  question: false,
  dailywork: false,
  correct: false,
  feedback: false,
  notice: false,
  answer: false,
  privacyphone: false,
  createpublic: false,
  reminderBool: false,
  publicBool:false,
  cloudBool:false,
};

const mutations = {
  // 清除弹出的组件(点击左侧任意班级后)
  CLEAR_BOOL: (state, data) => {
    state.classinforbool = data
    state.maillistbool = data
    state.course = data
    state.materials = data
    state.homework = data
    state.dailywork = data
    state.question = data
    state.correct = data
    state.feedback = data
    state.notice = data
    state.answer = data
    state.privacyphone = data
    state.createpublic = data
    state.cloudBool = data
  },
  //管理组件状态（1课表，2班级，3公开课）
  CHANGE_STATE: (state, data) => {
    state.changestate = data
  },
  // 管理通讯录组件
  SET_MAILLIST: (state, data) => {
    state.maillistbool = data
  },
  // 管理章节详细组件
  SET_CLASSINFOR: (state, data) => {
    state.classinforbool = data
  },
  // 管理调课组件
  SET_COURSEBOOL: (state, data) => {
    state.course = data
    // console.log(data);
  },
  // 管理课程资料组件
  SET_MATERIALS: (state, data) => {
    state.materials = data
  },
  // 管理日常作业
  SET_DAILYWORK: (state, data) => {
    state.dailywork = data
  },
  // 管理作业组件
  SET_HOMEWORK: (state, data) => {
    state.homework = data
  },
  // 管理题库组件
  SET_QUESTION: (state, data) => {
    state.question = data
  },
  // 管理批改作业组件
  SET_CORRECT: (state, data) => {
    state.correct = data
  },
  // 管理写反馈组件
  SET_FEEDBACK: (state, data) => {
    state.feedback = data
  },
  // 管理班级通知组件
  SET_NOTICE: (state, data) => {
    state.notice = data
  },
  //管理答题组件
  SET_ANSWER: (state, data) => {
    state.answer = data
  },
  //管理修改手机号组件组件
  SET_PRIVACY_PHONE: (state, data) => {
    state.privacyphone = data
  },
  //创建公开课
  SET_CREATEPUBLIC: (state, data) => {
    state.createpublic = data
  },
  //是否弹出课前提醒组件
  SET_REMINDER_BOOL: (state, data) => {
    state.reminderBool = data
    // console.log(data,'userbool');
  },
  //是否弹出分享组件
  SET_PUBLIC_BOOL: (state, data) => {
    state.publicBool = data
    // console.log(data,'publicBool');
  },
  //是否弹出云盘组件
  SET_CLOUD_BOOL: (state, data) => {
    state.cloudBool = data
  }
};

const actions = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
