import request from '@/utils/request.js'
// 老师端
//  账号登入
export const AccountLoginApi = (params) => {
  return request({
    url: '/api-tch/user/login',
    method: 'post',
    headers: {
      'content-type': 'multipart/form-data',
    },
    data: params,
  })
}
// 老师退出登入
export const tchLogouts = (data) => {
  return request({
    url: '/api-tch/user/logout',
    method: 'post',
    data,
  })
}
// 手机号验证码登入
export const PhoneLoginApi = (params) => {
  return request({
    url: '/api-tch/user/loginByMobileValidCode',
    method: 'post',
    params: params,
    headers: {
      'content-type': 'multipart/form-data',
    },
  })
}

// 获取用户信息
export const getUserInfoApi = () => {
  return request({
    url: '/api-tch/user/userInfo',
    method: 'post',
  })
}

// 查询手机区号下拉列表
export const findZoneList = () => {
  return request({
    url: '/api-user/common/phoneZoneList',
    method: 'post',
  })
}

// 根据ip地址获取当前的手机区号
export const getPhoneByNation = () => {
  return request({
    url: '/api-user/common/getPhoneByNation',
    method: 'post',
  })
}

// 手机号/密码登录
export const loginByMobliePassword = (data) => {
  return request({
    url: '/api-tch/user/loginByMobliePassword',
    method: 'post',
    data: data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}

// 获取登入验证码
export const sms = (data) => {
  return request({
    url: '/api-tch/user/code/sms',
    method: 'post',
    data: data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}



// 获取随机验证码
export const randomToken = (data) => {
  return request({
    url: '/api-tch/user/randomToken',
    method: 'post',
    data: data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}

//找回密码
export const retrievePwd = (data) => {
  return request({
    url: '/api-tch/user/retrievePwd',
    method: 'post',
    data: data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}

// 学生端

//  账号登入
export const AccountLoginStu = (params) => {
  return request({
    url: '/api-user/user/login',
    method: 'post',
    headers: {
      'content-type': 'multipart/form-data',
    },
    data: params,
  })
}
// 手机号验证码登入
export const PhoneLoginStu = (params) => {
  return request({
    url: '/api-user/user/loginByMobileValidCode',
    method: 'post',
    params: params,
    headers: {
      'content-type': 'multipart/form-data',
    },
  })
}
// 手机号/密码登录
export const loginByMobliePasswordStu = (data) => {
  return request({
    url: '/api-user/user/loginByMobliePassword',
    method: 'post',
    data: data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 获取用户信息
export const getUserInfoStu = (data) => {
  return request({
    url: '/api-user/user/userInfo',
    method: 'post',
    data: data,
    headers: {
      'content-type': "multipart/form-data",
      "clientType": '014005',
      "languageId": '1',
    }
  })
}
// 获取登入验证码
export const smsStu = (data) => {
  return request({
    url: '/api-user/user/code/sms',
    method: 'post',
    data: data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}

// 获取随机验证码
export const randomTokenStu = (data) => {
  return request({
    url: '/api-user/user/randomToken',
    method: 'post',
    data: data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}

//找回密码
export const retrievePwdStu = (data) => {
  return request({
    url: '/api-user/user/retrievePwd',
    method: 'post',
    data: data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 教师微信登录
export const webWx = (data) => {
  return request({
    url: '/api-tch/user/auth/webWx',
    method: 'post',
    params: data,
  })
}
// 手机号绑定 /api-user/user/phoneBind
export const phoneBind = (data) => {
  return request({
    url: '/api-tch/user/phoneBind',
    method: 'post',
    data: data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 学生微信登录
export const webWxStu = (data) => {
  return request({
    url: '/api-user/user/auth/webWx',
    method: 'post',
    data: data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 用户登出
export const logouts = (data) => {
  return request({
    url: '/api-user/user/logout',
    method: 'post',
    data,
  })
}
// C端用户强制登录(学生端)
export const forceLogin = (data) => {
  return request({
    url: '/api-user/user/forceLogin',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// C端用户强制登录 (教师端)
export const forceLoginTch = (data) => {
  return request({
    url: '/api-tch/user/forceLogin',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 双因子认证（学生）
export const twoFactorAuthStu = (data) => {
  return request({
    url: '/api-user/user/twoFactorAuth',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
// 双因子认证（教师）
export const twoFactorAuthTea = (data) => {
  return request({
    url: '/api-tch/user/twoFactorAuth',
    method: 'post',
    data,
    headers: {
      'content-type': "multipart/form-data",
    }
  })
}
