import { audioFn } from "@/utils/audio.js";

const state = {
  rtcToken: "", // 加入房间的token
  liveType: "",
  imArr: [], // im聊天记录列表
  chatRoomId: "", // 房间id
  studentList: [], // 学生列表 为了给im获取名字
  isForbidChat: false, // 学生端 是否禁言
  tchInvitationchat: false, // 老师是否有邀请开启聊天
  unreadMessage: 0, // 未读消息数
  imLogin: false, // im登入了  获取到了im的token
  classStartTime: "", // 课程开始时间
  liveInvitationAudio: null, // 连麦声音
  liveHandAudio: null, // 举手和收到消息声音
  liveAudioStatus: true, // 是否开启声音音频
  imIsError: false, // im连接是否错误
  imErrorMsg: "", // im连接错误信息
  classId: 0, //白板使用的班级id
  showBarrage:true,//是否显示弹幕
};

const mutations = {
  // 设置修改是否显示弹幕
  SET_SHOW_BARRAGE(state, value) {
    state.showBarrage = value;
  },
  // 设置im错误信息
  SET_IM_ERROR_MSG(state, value) {
    state.imErrorMsg = value;
  },
  // 设置im是否错误
  SET_IM_IS_ERROR(state, value) {
    state.imIsError = value;
  },
  // 设置声音音频状态
  SET_LIVE_AUDIO_STATUS(state, value) {
    state.liveAudioStatus = value;
    if (
      !value &&
      state.liveInvitationAudio != null &&
      state.liveHandAudio != null
    ) {
      state.liveInvitationAudio.stop();
      state.liveHandAudio.stop();
    }
  },
  // 初始化连麦声音和举手声音
  INIT_AUDIO(state, value) {
    state.liveInvitationAudio = audioFn({
      url: "https://image.hycooby.com/hwjy/image/sound/lianmai.mp3?v=1.0.0",
      loop: true,
      preload: true,
    });
    state.liveHandAudio = audioFn({
      url: "https://image.hycooby.com/hwjy/image/sound/wenzijushou.mp3?v=1.0.0",
      loop: false,
      preload: true,
    });
  },
  // 播放连麦声音
  PLAY_LIVE_INVITATION_AUDIO(state) {
    if (state.liveAudioStatus) {
      state.liveInvitationAudio.play();
    }
  },
  // 播放举手声音
  PLAY_LIVE_HAND_AUDIO(state) {
    if (state.liveAudioStatus) {
      state.liveHandAudio.play();
    }
  },
  // 暂停连麦声音
  PAUSE_LIVE_INVITATION_AUDIO(state) {
    if (state.liveAudioStatus) {
      state.liveInvitationAudio.stop();
    }
  },
  // 暂停举手声音
  PAUSE_LIVE_HAND_AUDIO(state) {
    if (state.liveAudioStatus) {
      state.liveHandAudio.stop();
    }
  },
  // 设置直播类型
  SET_LIVE_TYPE(state, value) {
    state.liveType = value;
  },
  // 添加im聊天记录
  SET_IM_ARR(state, value) {
    setTimeout(() => {
      // 滚动的距离
      let scrollHeight = document.querySelector(".im .message-box").scrollTop;
      // 消息盒子的高度
      let mesBoxHeight =
        document.querySelector(".im .message-box").offsetHeight;
      // 消息盒子内部的总高度
      let mesListHeight = document.querySelector(
        ".im .message-box .message-list"
      ).offsetHeight;
      // 消息盒子的padding值
      let mesListPadding = window.getComputedStyle(
        document.querySelector(".im .message-box")
      ).padding;
      // 获取padding值
      let pad = parseInt(mesListPadding.split("px")[0]) * 2;
      // 收到消息时 如果在底部 来消息也滚动到底部
      // console.log(scrollHeight,mesBoxHeight,mesListHeight,mesListPadding);
      // console.log(scrollHeight + mesBoxHeight);
      // console.log(mesListHeight + pad);
      // 滚动到距离底部100px  如果来消息 就滚动到最底部
      if (scrollHeight + mesBoxHeight + 100 > mesListHeight + pad) {
        state.imArr.push(value);
        setTimeout(() => {
          let newHeight = document.querySelector(
            ".im .message-box .message-list"
          ).offsetHeight;
          document.querySelector(".im .message-box").scrollTop = newHeight;
        }, 10);
      } else if (mesListHeight < mesBoxHeight) {
        state.imArr.push(value);
      } else {
        // 没有在底部的话  就只要添加就好了
        state.imArr.push(value);
      }
      setTimeout(() => {
        // 弹幕滚动到最底部
        let barrageHeight = document.querySelector(
          ".barrage-live .barrage-box"
        ).offsetHeight;
        document.querySelector(".barrage-live").scrollTop = barrageHeight;
      }, 10);
    }, 10);
  },
  // 滚动到底部 清空未读消息
  scrollToBottom(state) {
    setTimeout(() => {
      let newHeight = document.querySelector(
        ".im .message-box .message-list"
      ).offsetHeight;
      document.querySelector(".im .message-box").scrollTop = newHeight;

      // 弹幕滚动到最底部
      let barrageHeight = document.querySelector(
        ".barrage-live .barrage-box"
      ).offsetHeight;
      document.querySelector(".barrage-live").scrollTop = barrageHeight;
    }, 10);
  },
  // 添加历史消息
  SET_IM_HISTORY(state, value) {
    state.imArr.unshift(value);
  },
  // 清除历史消息
  CLEAR_IM_HISTORY(state) {
    state.imArr = [];
    state.unreadMessage = 0;
  },
  // 清除消息提示
  CLEAR_IM_TIP(state) {
    state.unreadMessage = 0;
  },
  // 添加消息提示
  ADD_IM_TIP(state) {
    state.unreadMessage += 1;
  },
  // 设置房间id
  SET_CHAT_ROOM_ID(state, value) {
    state.chatRoomId = value;
  },
  // 设置学生列表
  SET_STUDENT_LIST(state, value) {
    state.studentList = value;
  },
  // 设置禁言状态
  SET_IS_FORBID_CHAT(state, value) {
    state.isForbidChat = value;
  },
  // 设置老师是否有邀请开启聊天
  SET_TCH_INVITATIONCHAT(state, value) {
    state.tchInvitationchat = value;
  },
  // 设置im登入状态的改变
  SET_IM_LOGIN(state, value) {
    state.imLogin = value;
  },
  // 设置课程开始时间
  SET_CLASS_START_TIME(state, value) {
    state.classStartTime = value;
  },
  setClassId(state, value) {
    state.classId = value;
  },
};

const actions = {
  setLiveType({ commit }, value) {
    commit("SET_LIVE_TYPE", value);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
