//  写上role  是为了通过 路由权限 谁都能访问 成为公共路由
export const mobileRouter = [
  {
    path: '/homework',
    component: () => import("@/views/mobileView/examination/homework.vue"),
    hidden: true,
    meta: {
      title: '日常作业',
      role: 'admin'
    }
  },
  {
    path: '/homework-teacher',
    component: () => import("@/views/mobileView/examination/homework-teacher.vue"),
    hidden: true,
    meta: {
      title: '日常作业批改',
      role: 'admin'
    }
  },
  {
    path: '/homework-analyze',
    component: () => import("@/views/mobileView/examination/homework-analyze.vue"),
    hidden: true,
    meta: {
      title: '日常作业查看',
      role: 'admin'
    }
  },
  {
    path: '/examination',
    component: () => import("@/views/mobileView/examination/examination.vue"),
    hidden: true,
    meta: {
      title: '写作业',
      role: 'admin'
    }
  },
  {
    path: '/success',
    component: () => import("@/views/mobileView/examination/success.vue"),
    hidden: true,
    meta: {
      title: '提交成功',
      role: 'admin'
    }
  },
  {
    path: '/homeworkComments',
    component: () => import("@/views/mobileView/examination/homeworkComments.vue"),
    hidden: true,
    meta: {
      title: '作业点评',
      role: 'admin'
    }
  },
  {
    path: '/corrects',
    component: () => import("@/views/mobileView/examination/corrects.vue"),
    hidden: true,
    meta: {
      title: '批改',
      role: 'admin'
    }
  },
  {
    path: '/introduction',
    component: () => import("@/views/mobileView/Introduction/course-Introduction.vue"),
    hidden: true,
    meta: {
      title: '课程简介',
      role: 'admin'
    }
  },
  {
    path: '/process',
    component: () => import("@/views/mobileView/classprocess/class-Process.vue"),
    hidden: true,
    meta: {
      title: '上课流程',
      role: 'admin'
    }
  },
  {
    path: '/report',
    component: () => import("@/views/mobileView/studyReport/study-Report.vue"),
    hidden: true,
    meta: {
      title: '学习报告',
      role: 'admin'
    }
  },
  {
    path: '/preview',
    component: () => import("@/views/mobileView/examination/preview.vue"),
    hidden: true,
    meta: {
      title: '作业预览',
      role: 'admin'
    }
  },
  {
    path: '/analyze',
    component: () => import("@/views/mobileView/examination/analyze.vue"),
    hidden: true,
    meta: {
      title: '作业预览',
      role: 'admin'
    }
  },
  {
    path: '/downLoad',
    component: () => import("@/views/mobileView/downLoad/downLoad.vue"),
    hidden: true,
    meta: {
      title: '下载',
      role: 'admin'
    }
  },
  {
    path: '/cancelAccount',
    component: () => import("@/views/mobileView/cancelAccount/cancelAccount.vue"),
    hidden: true,
    meta: {
      title: '注销账号',
      role: 'admin'
    }
  },
  {
    path: '/huaxiao',
    component: () => import("@/views/mobileView/huaxiao/index.vue"),
    hidden: true,
    meta: {
      title: '华校',
      role: 'admin'
    }
  },
  {
    path: '/huaxiaoDetail',
    component: () => import("@/views/mobileView/huaxiao/detail.vue"),
    hidden: true,
    meta: {
      title: '华校详情',
      role: 'admin'
    }
  },
  {
    path: '/orgDetailDesc',
    component: () => import("@/views/mobileView/huaxiao/orgDetailDesc.vue"),
    hidden: true,
    meta: {
      title: '华校详情简介',
      role: 'admin'
    }
  },
  {
    path: '/courseList',
    component: () => import("@/views/mobileView/huaxiao/courseList.vue"),
    hidden: true,
    meta: {
      title: '课程详情',
      role: 'admin'
    }
  },
  {
    path: '/supervision',
    component: () => import("@/views/mobileView/supervision/index.vue"),
    hidden: true,
    meta: {
      title: '家长监督',
      role: 'admin'
    }
  },
  {
    path: '/supervision-detail',
    component: () => import("@/views/mobileView/supervision/detail.vue"),
    hidden: true,
    meta: {
      title: '家长监督详情',
      role: 'admin'
    }
  },
  {
    path: '/buy-course',
    component: () => import("@/views/mobileView/share/buyCourse.vue"),
    hidden: true,
    meta: {
      title: '购买课程',
      role: 'admin'
    }
  },
  {
    path: '/buy-course-more',
    component: () => import("@/views/mobileView/share/courseListMore.vue"),
    hidden: true,
    meta: {
      title: '购买课程',
      role: 'admin'
    }
  },
  {
    path: '/course-detail',
    component: () => import("@/views/mobileView/share/courseDetail.vue"),
    hidden: true,
    meta: {
      title: '课程详情',
      role: 'admin'
    }
  },
  {
    path: '/huaxiao-detail',
    component: () => import("@/views/mobileView/share/huaxiaoDetail.vue"),
    hidden: true,
    meta: {
      title: '课程详情',
      role: 'admin'
    }
  },
  {
    path: '/huaxiao-detail-desc',
    component: () => import("@/views/mobileView/share/huaxiaoDetailDesc.vue"),
    hidden: true,
    meta: {
      title: '课程详情',
      role: 'admin'
    }
  },
  {
    path: '/contract',
    component: () => import("@/views/mobileView/contract/index.vue"),
    hidden: true,
    meta: {
      title: '签订合同',
      role: 'admin'
    }
  }

]
